import './kycStatus.css'
import {useState} from "react";
import {B2B_KYC} from "../../navigation/CONSTANTS";
import Cache from "../../utils/cache"
import {ACTIONS} from "../B2BKYC/constants";
const cache = Cache()

const KycStatus = ({cache_id, form_key, index, className, updateCallback}) => {
    const parentKey = form_key.split('.').slice(0, -1).join('.')
    cache.setRef(cache_id)
    const cardholderRefKey = `${parentKey}.cardholder_ref`
    const storedCardholderRef = cache.getItem(cardholderRefKey).val
    const personUrl = `/${B2B_KYC}?ref=${cache_id}&key=${parentKey}`
    let newCardholderRef
    if(!storedCardholderRef) {
        const prefix = cache.getItem("prefix").val || "NOJIRA"
        const suffix = Math.floor(Math.random() * 10000000).toString(16)
        newCardholderRef = prefix + '-' + suffix
        cache.setItem(cardholderRefKey, newCardholderRef)
    }
    const [status, setStatus] = useState('')
    const [cardholder_ref, setCardholderRef] = useState(storedCardholderRef || newCardholderRef)

    const updateStatus = (e) => {
        setStatus(e.currentTarget.value)
    }
    const updateCardholderRef = (e) => {
        setCardholderRef(e.currentTarget.value)
    }

    let action = ACTIONS[status] || ACTIONS.default

    return <div className={`form-field kyc-status ${className} status-${status} `}>
        <button className="autoclick" data-id={`${form_key}`} onClick={updateStatus} >autoclick</button>
        <button className="autoclick" data-id={`${parentKey}.cardholder_ref`} onClick={updateCardholderRef} >autoclick</button>
        <small>{cardholder_ref}</small>
        <a href={personUrl} className="button">{action}</a>
    </div>
}


export default KycStatus