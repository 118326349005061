import React, { useState } from 'react';
import { DefaultButton } from '../../components/button/index';
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../components/formRows/formRows';
import { useTranslation } from "react-i18next";
import { Form } from 'formik';
import { CustomTextField } from '../../components/textField/textField';



const NumberForm = (props) => {
    const { t } = useTranslation();
    const [numberChange, setNumberChange] = useState(false)
    const {
        handleChange,
        handleSubmit,
        numberEdit,
    } = props;

    const onNumberEdit = (e) => {
        handleChange(e);
        setNumberChange(true)
    }

    return (
        <Form>
            <div className='padDiv'>

                <CustomTextField {...props} fieldName="mobile_number" autoComplete="mobile-number" disabled={!numberEdit} handleChange={onNumberEdit} />

                <FormSpacer />

                {numberChange && <FormRowWithLabel className='next-button-container'>
                    <FormLabel />
                    <DefaultButton onClick={handleSubmit} name={t('resend-code')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>}
            </div>
        </Form >

    );
}

export default NumberForm;
