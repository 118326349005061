import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppFooter, AppHeader } from './components/layout';
import RouterConfig from './navigation/RouterConfig';
import {
    RecoilRoot
} from 'recoil';

function App() {
    return (
        <RecoilRoot>
            <BrowserRouter >
                {/*<AppHeader />*/}
                {/* <div className='before' /> */}
                <RouterConfig />
                {/*<AppFooter />*/}
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default App;