import ClevaAPI from "../../api/ClevaAPI";
import {useEffect, useRef, useState} from "react";
import './documentSlot.css'
import Camera from "./camera";


const DocumentSlot = ({cacheRef, personRef, label, existingFileMeta, docType, onUploadComplete, placeholderImg, children}) => {
    const api = ClevaAPI()
    const [uploadedFile, setUploadedFile] = useState(existingFileMeta)
    const [cameraVisible, setCameraVisible] = useState(false)
    const [videoInputs, setVideoInputs] = useState([])
    const IMAGE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'] // application/pdf
    const MAX_FILES_DROP_NUM = 1
    const MBYTE = 1048576
    const MAX_TOTAL_UPLOAD_TOTAL = 5 * MBYTE

    const validateFile = (files) => {
        if(files.length > MAX_FILES_DROP_NUM) {
            alert(`Max ${MAX_FILES_DROP_NUM} file(s) at a time`)
            return false
        }
        const f = files[0]
        if(!IMAGE_TYPES.includes(f.type)) {
            alert(`We accept only ${IMAGE_TYPES.join(', ')} formats. The "${f.type}" format is not OK.`)
            return false
        }

        if(f.size > MAX_TOTAL_UPLOAD_TOTAL) {
            const maxRounded = (MAX_TOTAL_UPLOAD_TOTAL / MBYTE).toFixed(1)
            const totalRounded = (f.size / MBYTE).toFixed(1)
            alert(`The file size is too big, we accept ${maxRounded} MB and your file is ${totalRounded} MB, ' + 
                precisely ${f.size}. Try lowering the resolution or downsizing the image.`)
            return false
        }
        return f
    }

    const handleDrop = (e) => {
        let dt = e.dataTransfer
        let file = validateFile([...dt.files])
        processPreview(file)
    }

    const processPreview = (file) => {
        previewFile(file).then(setUploadedFile)
    }

    const previewFile = async (f) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                resolve({src:reader.result, file:f});
            };
            reader.onerror = reject;
            reader.readAsDataURL(f);
        })
    }

    const UploadedFile = ({init}) => {
        const [meta, setMeta] = useState(init)
        //console.log("UploadedFile", init)
        const refresh = (newMeta)  => {
            console.log("updating Meta", newMeta)
            setMeta({...newMeta})
        }
        return <UploadedFileCtrl meta={meta} onUpdate={refresh}/>
    }

    const UploadedFileCtrl = ({meta, onUpdate}) => {
        if(!meta) return ""
        //console.log("UploadedFileCtrl", meta)
        const openImg = (e) => {
            if(meta.url) window.open(meta.url,"_blank")
            else debugger
        }
        const onUploaded = response => {
            //console.log("uploaded response:", response)
            meta.url = response.url
            onUpdate(meta)
        }
        const onProgress = (p) => {
            //console.log("percent:",p)
            meta.percent = p
            onUpdate(meta)
        }
        let pollProcessingTimer
        const schedulePollProcessing = () => {
            //console.log("scheduling processing check in 2s")
            pollProcessingTimer = setTimeout(pollProcessing, 2000)
        }
        const pollProcessing = async () => {
            const res = await api.checkScanResult(cacheRef, personRef, meta.url)
            if(res && res.idscan_result) {
                //console.log("processing check successful", res)
                meta.idscan_result = res.idscan_result
                onUpdate(meta)
                onUploadComplete(docType, meta)
            } else {
                schedulePollProcessing()
            }
        }

        if( !meta.onProgress ) {
            meta.onProgress = onProgress
            uploadFile(meta.file, onProgress).then( onUploaded ).catch( err => {
                console.error(err)
                alert("Uploading error" + String(err))
            })
        }else{
            const previousUrl = existingFileMeta && existingFileMeta.url || ''
            if(!meta.idscan_result && meta.url && onUploadComplete && meta.url != previousUrl) {
                schedulePollProcessing()
            }
        }

        let resultMsg = <span className="icon">&#9728;</span>
        let statusCss = (!meta.idscan_result && meta.url) ? 'processing' : ''
        let actionMsg = ''
        try {
            if (meta && meta.idscan_result && meta.idscan_result.resultIdScan) {
                const scanRes = meta.idscan_result
                if (meta.idscan_result.resultIdScan.entryData) { // "lowercase initial" API response
                    resultMsg = scanRes.resultIdScan.entryData.overallAuthenticationState
                    actionMsg = scanRes.resultIdScan.cardholderAction
                }
                if (meta.idscan_result.resultIdScan.EntryData) { // "uppercase initial" API response
                    resultMsg = scanRes.resultIdScan.EntryData.OverallAuthenticationState
                    actionMsg = scanRes.resultIdScan.cardholderAction
                }
                statusCss = resultMsg.toLowerCase()
            }
        }catch(e){
            console.error(e, meta.idscan_result)
        }
        const isPDF = meta.file.type === 'application/pdf'
        // console.log("the MIME: ", meta.file.type)
        // console.log("rendering UploadedFile", meta)
        return <div className={`uploadedFile ${statusCss}`}>
            {isPDF ?
                <embed src={meta.src} onClick={openImg}/> :
                <img src={meta.src} onClick={openImg}/>
            }
            <progress max="100" value={meta.percent} className={meta.percent === 100 ? 'hidden' : ''}/>
            <span className="resultMsg">{resultMsg}</span>
            <span className="actionMsg">{actionMsg}</span>
        </div>
    }

    const uploadFile = (f, onProgress = false) => {
        return api.uploadScan(cacheRef, personRef, docType, f, onProgress)
    }

    const getVideoDevices = async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices()
        const vi = allDevices.filter( d => d.kind === 'videoinput')
        setVideoInputs(vi)
    }


    const EVENTS = ['dragenter', 'dragover', 'dragleave', 'drop']

    const closestParentFormField = (el, level=5) => {
        if(el.classList.contains('form-field')) return el
        if(level > 0) return closestParentFormField(el.parentElement, level-1)
        throw new Error("Parent form-field not found within given depth")
    }

    const handleDragAndDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const el = closestParentFormField(e.target)
        try{
            switch(e.type){
                case "dragenter":
                case "dragover":
                    el.classList.add('draggedover');
                    break;
                case "dragleave":
                    el.classList.remove('draggedover');
                    break;
                case "drop":
                    el.classList.remove('draggedover');
                    handleDrop(e)
                    break;
            }
        }catch(err){
            console.log("ERROR", e, el, err)
        }
    }


    const toggleCamera = (e) => {
        setCameraVisible(!cameraVisible)
    }


    const handleClick = (e) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = IMAGE_TYPES.join(', ')
        input.onchange = _ => {
                processPreview(validateFile([...input.files]))
            };
        input.click();
    }

    const handleCameraImage = (file) => {
        processPreview(validateFile([file]))
        setCameraVisible(false)
    }

    const myself = useRef()
    const setupDragAndDropHandler = () => {
        const el = myself.current
        EVENTS.forEach(eventName => {
          el.addEventListener(eventName, handleDragAndDrop, false)
        })
        return () => {
            EVENTS.forEach(eventName => {
              el.removeEventListener(eventName, handleDragAndDrop, false)
            })
        }
    }

    const loadLastUploaded = async () => {
        const res = await api.checkScanResult(cacheRef, personRef, '', docType)
        res.onProgress = true
        res.src = res.url
        res.percent = 100
        res.file = {
            type:res.type
        }
        setUploadedFile(res)
    }


    useEffect(setupDragAndDropHandler, [])
    //useEffect(loadLastUploaded, [])


    return <div className="documentSlot" ref={myself}>
        <h2> {label} </h2>
        <div>
            {children}
        </div>
        <div className="buttons">
            <button id={`btn-add`} className="auxiliary" onClick={handleClick}>add file</button>
            <button id={`btn-capture`} className="auxiliary" onClick={toggleCamera}>take photo</button>
        </div>
        <UploadedFile init={uploadedFile}/>
        {(!uploadedFile && placeholderImg) ? <img className="placeholder" src={placeholderImg}/> : ''}
        {cameraVisible ? <Camera imageReadyCallback={handleCameraImage}></Camera> : ""}
    </div>
}

export default DocumentSlot