import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import ClevaAPI from "../../api/ClevaAPI"
// import { postAPI } from "../../api";
import Loader from '../../components/loader/loader'
import { DefaultButton, AuxButton } from '../../components/button/index';
// import Card from '@material-ui/core/Card';
// import './index.css'
import { HorizontalRow, FormLabel, FormSpacer, ConfirmValue } from "../../components/formRows/formRows";
// import PolicyIcon from '@material-ui/icons/Policy';
// import { trackConversion } from "../../common/trackers";
import { useNavigate } from "react-router-dom";
import GetCleva from '../../components/getCleva';

// const useStyles = makeStyles(() => ({
//     center: {
//         margin: 'auto'
//     },
//     change: {
//         marginTop: '15px',
//         marginRight: '13px',
//         fontWeight: 500,
//         color: 'rgb(255, 131, 0)',
//         float: 'right',
//         textDecoration: 'underline',
//         cursor: 'pointer'
//     },
//     policyIcon: {
//         color: '#ccddff',
//         width: '3em',
//         height: '3em',
//     }
// }));

const Preview = (props) => {
    const { t } = useTranslation();
    //const api = ClevaAPI()
    let navigate = useNavigate();

    // const classes = useStyles()
    // const [error, setError] = useState(false)
    let kyc = JSON.parse(sessionStorage.getItem('user_data'))

    const backToEdit = () => {
        navigate(-1)
    }

    const handleSubmit = () => {
        navigate("/mobile-verification")
    }

    const dateFormat = new Intl.DateTimeFormat(
        "en-GB",
        {
            year: "numeric",
            month: "long",
            day: "2-digit"
        })

    const dateFormatter = (d, m, y) => {
        const date = new Date(y, m - 1, d)
        let formatedDate = ''
        try {
            formatedDate = dateFormat.format(date)
        } catch {
            formatedDate = ''
        }
        return formatedDate
    }


    return (

        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                <GetCleva heading={t('confirm-details')} subHeading={`${t('preview.message1')} ${t('preview.message2')}`} />
                {/* <h1>{ }</h1>
                <p className="confirm-info">
                    {t('preview.message1')}
                    {t('preview.message2')}
                </p> */}
                <Grid container>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    <Grid item xs={12}
                        md={10}
                        lg={7}
                        xl={6}>
                        {/* <HorizontalRow>
                            <FormLabel>
                                <PolicyIcon className={classes.policyIcon} /></FormLabel> */}

                        {/* </HorizontalRow> */}
                        <FormSpacer />
                        <HorizontalRow>
                            <FormLabel>{t('email')}:</FormLabel>
                            <ConfirmValue>{kyc.email}</ConfirmValue>
                        </HorizontalRow>
                        <HorizontalRow>
                            <FormLabel>{t('preview.name')}:</FormLabel>
                            <ConfirmValue>{kyc.first_name} {kyc.last_name}</ConfirmValue>
                        </HorizontalRow>
                        <HorizontalRow>
                            <FormLabel>{t('mobile_number')}:</FormLabel>
                            <ConfirmValue>{kyc.mobile_number}</ConfirmValue>
                        </HorizontalRow>
                        <HorizontalRow>
                            <FormLabel>{t('preview.address')}:</FormLabel>
                            <ConfirmValue>{kyc.address1}</ConfirmValue>
                            <ConfirmValue>{kyc.address2}</ConfirmValue>
                            <ConfirmValue>{kyc.city}</ConfirmValue>
                            <ConfirmValue>{kyc.postcode}</ConfirmValue>
                        </HorizontalRow>
                        <HorizontalRow>
                            <FormLabel>{t('date-of-birth')}:</FormLabel>
                            <ConfirmValue>{dateFormatter(kyc.day, kyc.month, kyc.year)}</ConfirmValue>
                        </HorizontalRow>
                        <FormSpacer />
                        <Grid container className='next-button-container'>
                            <HorizontalRow>
                                <AuxButton onClick={backToEdit} name='Change' id='change-details' />
                                <DefaultButton onClick={handleSubmit} name='Confirm' id='next-btn'></DefaultButton>
                            </HorizontalRow>
                        </Grid>
                        {/* <p className='errorMessage'>{error}</p> */}
                        <Loader />
                    </Grid></Grid></Grid></div>
    )
}

// const mapStateToProps = state => ({
//     Children: state.children,
//     Kyc: state.kyc,
//     Order: state.order

// })

// const mapDispatchToProps = dispatch => {
//     return {
//         saveKyc: (value) => dispatch({ type: "SET_KYC", value }),
//         saveChildren: (value) => dispatch({ type: "SET_CHILDREN", value }),
//         clearSteps: () => dispatch({ type: "CLEAR_STEP" }),

//     }
// };



// export default connect(mapStateToProps, mapDispatchToProps)(Preview);
export default Preview
