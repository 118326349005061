import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

const GetCleva = (props) => {
    // const { t } = useTranslation();

    const { heading, subHeading } = props;

    return (
        <Grid container>
            <Grid item
                md={1}
            />
            <Grid item xs={12}
                md={8}
                lg={8}>
                <div className='sectionHeader'>
                    <h4 className="heading">{heading}</h4>
                </div>
                <div>{subHeading}</div>
            </Grid>
            <Grid item md={3} />
            <Grid />
        </Grid>
    );
}


export default GetCleva;

