import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// import Loader from '../../ui-components/loader/loader'
import './index.css'
import { Formik, Form } from 'formik';
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../components/formRows/formRows';
import { CustomTextField } from '../../components/textField/textField';
import { DefaultButton } from '../../components/button/index';
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";




const CheckOut = () => {

    const [numberOfCards, setNumberOfCards] = useState(0);

    const { t } = useTranslation();
    let navigate = useNavigate();




    const validationSchema = Yup.object({
        number_of_cards: Yup.number()
            .required(`${t('field-rqd')} ${t('number_of_cards_placeholder')}`)
    });

    const handleSubmit = () => {

        navigate('/payment', { state: { monthly_fee: numberOfCards * 10, signup_fee: 20, total: numberOfCards * 10 + 20 } });
    }

    const handleChange = (props, e) => {
        props.handleChange(e)
        setNumberOfCards(e.target.value)
    }

    return (
        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                {/* <GetCleva /> */}
                <Grid container>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    <Grid item xs={12}
                        md={10}
                        lg={7}
                        xl={6}>
                        <Formik
                            initialValues={{ number_of_cards: "" }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(props) => (<><Form>
                                <div className='padDiv'>
                                    <CustomTextField {...props} handleChange={(e) => handleChange(props, e)}
                                        fieldName="number_of_cards" autoComplete="code" type="number" />
                                    <FormSpacer />
                                </div>
                            </Form>
                                <div className="form-group confirm-kyc-group text-left">
                                    <div >
                                        <div className="checkout-detail">
                                            <span className="confirm-value">{t('monthly-fee')}:</span>
                                            <span className="confirm-value">{numberOfCards * 10}</span>
                                        </div>
                                        <div className="checkout-detail">
                                            <span className="confirm-value">{t('signup-fee')}:</span>
                                            <span className="confirm-value">{20}</span>
                                        </div>

                                    </div>
                                    <br />

                                    <div >
                                        <div className="checkout-detail">
                                            <span className="confirm-value">{t('total')}</span>
                                            <span className="confirm-value">{numberOfCards * 10 + 20}</span>
                                        </div>
                                    </div>

                                </div>
                                <FormRowWithLabel className='next-button-container'>
                                    <FormLabel />
                                    <DefaultButton onClick={props.handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                                </FormRowWithLabel>
                            </>)}

                        </Formik>
                    </Grid>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    {/* <Loader /> */}
                </Grid></Grid></div>
    )

}






export default CheckOut
