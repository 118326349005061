import React, { useState } from 'react'
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import Form from './form';
// import ClevaAPI from '../api/ClevaAPI'
import GetCleva from '../../components/getCleva';
import { useNavigate } from "react-router-dom";
import NumberForm from './numberForm';
import { FormSpacer } from '../../components/formRows/formRows';

const Home = () => {

    const mobile_number = sessionStorage.getItem("mobile_number")

    const [numberEdit, setNumberEdit] = useState(false)
    const [codeError, setCodeError] = useState(false)

    const { t } = useTranslation();

    let navigate = useNavigate();

    const initialValues = {
        code: "",
    }
    const initialValuesSecondary = {
        mobile_number: mobile_number,
    }

    // const validationSchema = Yup.object({})

    const validationSchemaSecondary = Yup.object({
        mobile_number: Yup.string()
            .required(`${t('field-rqd')} ${t('mobile_number_placeholder')}`)
        // .matches(/^07[0-9]{9}$/, t('_validation')),
    });

    const validationSchema = Yup.object({
        code: Yup.string()
            .required(`${t('field-rqd')} ${t('code')}`)
            .matches(/^[0-9]{4}$/, t('code-validation')),
    });

    const handleSubmit = (values) => {
        console.log(numberEdit ? values : { mobile_number: mobile_number })
        // setCodeError("error") //make it true for failed case
        navigate('/kyc-progress')

    }

    const handleSubmitSecondary = (values) => {
        console.log(values)
        setCodeError(false)
        setNumberEdit(false)
    }

    const changeNumber = () => {
        setNumberEdit(true)
    }

    return ( /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                <GetCleva heading={t('confirm-mobile')} />
                <Grid container>
                    <Child>
                        <FormSpacer />
                        <div className='padDiv'>
                            {t('code-hint')}</div>
                    </Child>
                    <Child>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            {(props) => (<Form {...props} error={codeError} />)}

                        </Formik>
                    </Child>

                    <Child>
                        <FormSpacer />
                        <div className='padDiv'>
                            {`${t('mobile_number_label')} ${mobile_number}`}<span onClick={changeNumber} className="cursor">{`[${t('change')}]`}</span></div>
                    </Child>
                    {numberEdit && <Child>
                        <Formik
                            initialValues={initialValuesSecondary}
                            enableReinitialize
                            validationSchema={numberEdit ? validationSchemaSecondary : null}
                            onSubmit={handleSubmitSecondary}>
                            {(props) => (<NumberForm {...props}
                                numberEdit={numberEdit}
                            />)}
                        </Formik>
                    </Child>}
                </Grid>

            </Grid>
        </div >
    )
}


const Child = (props) => {
    return <> <Grid item
        md={1}
        lg={2}
        xl={3} />
        <Grid item xs={12}
            md={10}
            lg={7}
            xl={6}>
            {props.children}
        </Grid>
        <Grid item
            md={1}
            lg={2}
            xl={3} /></>
}

export default Home