const KYC_STATUS = {
    EKYC: 'EKYC',
    SCAN: 'SCAN',
    PASS: 'PASS',
    FAIL: 'FAIL',
}

const ACTIONS = {
    EKYC: "please provide details",
    SCAN: "please upload documents",
    PASS: "ID & address confirmed",
    FAIL: "completed",
    default: "please provide details"
}

export default KYC_STATUS
export {ACTIONS}