import markdown from "../../utils/markdown";
import { subYears } from "date-fns";
import {useState} from "react";
import {compareBy} from "../../utils/commonUtils";
import "./kybFormComponents.css"

const FormSection = ({index, title, subtitle, info, children}) => {
    return <div className="form-section">
        <header>
            <b>{index}</b> <LabelWithMarkup md={title}/>
            <span className="subtitle">{subtitle}</span>
            <span className="info"><p>{info}</p></span>
        </header>
        {children}
    </div>
}

const ChecklistWithOthers = ({options, id}) => {
    const [othersVisible, setOthersVisible] = useState()
    const toggle = (e) => {
        setOthersVisible(e.currentTarget.checked)
    }
    return options ? <ul className="checklist" data-id={id}>
                {options.map( item => {
                    const key = `${id}.${item.id}`
                    return <li key={key} >
                        <input type="checkbox" data-id={key} id={key}/>
                        <LabelWithMarkup md={item.label} htmlFor={key}/>
                    </li>
                })}
                <li key={`${id}.other`}>
                    <input type="checkbox" data-id={`${id}.other`} id={`${id}.other`}/>
                    <LabelWithMarkup md="Other (describe):" htmlFor={`${id}.other`}/>
                </li>
                <li key={`${id}.other-desc`} >
                    <textarea data-id={`${id}.other-desc`}></textarea>
                </li>
            </ul> :
                <LabelWithMarkup md="no options provided"/>
}

const TODAY = new Date()
const HUNDRED_YEARS_AGO = subYears(new Date(), 100)

const FormField = ({label, id, type, options, warning, defaultValue, className='', inputs, inRef, children}) => {
    if(children) inputs = children
    switch(type) {
        case "warning":
            inputs = ""
            break;
        case "file":
            inputs = inputs || <span>
                <input type="file" id={id} name={id} data-id={id} />
                <button id={`btn-${id}`} className="auxiliary">add file</button>
            </span>
            break;

        case "yesno":
            inputs = inputs || <div className="checklist yesno" data-id={id} >
                <div>
                    <input type="radio" name={id} data-id={id} id={`${id}.yes`} value="yes"/>
                    <label htmlFor={`${id}.yes`}>Yes</label>
                </div>
                <div>
                    <input type="radio" name={id} data-id={id}  id={`${id}.no`} value="no"/>
                    <label htmlFor={`${id}.no`}>No</label>
                </div>
            </div>
            break;
        case "matrix":
            inputs = inputs || <table className="matrix" data-id={id}><thead>
                <tr key={id}><th></th>
                    {options.columns.map(col => <th key={col.id}>{col.label}</th>)}
                </tr>
            </thead><tbody>
                {options.rows.map( (row, i) =>
                <tr id={row.id}>
                    <th key={row.id+'-header'}>{row.label}</th>
                    {options.columns.map(col => {
                        const cellid = `${row.id}-${col.id}`
                        const celltype = col.type || ''
                        return <td key={col.id}>
                            {/*<input data-id={id} />*/}
                            <FormField id={cellid} type={celltype} options={col.options}/>
                        </td>
                    })}
                </tr>
                )}
            </tbody></table>
            break;
        case "checklist":
            inputs = inputs || options ? <ul className="checklist" data-id={id}>
                {options.map( item => {
                    const key = `${id}.${item.id}`
                    return <li key={key} >
                        <input type="checkbox" data-id={key} id={key}/><LabelWithMarkup md={item.label} htmlFor={key}/>
                    </li>
                })}
            </ul> :
                <LabelWithMarkup md="no options provided"/>
            break;
        case "checklist-with-others":
            inputs = inputs || <ChecklistWithOthers options={options} id={id}/>
            break;
        case "textarea":
            inputs = inputs || <textarea data-id={id} id={id} >{defaultValue}</textarea>
            break;
        case "percentage":
            inputs = inputs || <input defaultValue={defaultValue}
                                      data-id={id}
                                      id={id}
                                      type="number"
                                      min="1"
                                      max="100"
                                      className={type} />
            break;
        case "select":
            inputs = inputs || <select data-id={id} id={id} key={id} className={type} defaultValue={defaultValue}>
                    <option value="">select</option>
                    {options ? options.map(o => <option value={o.key} key={o.key}>{o.value}</option>) : ''}
                </select>
            break;
        case "date":
            inputs = inputs || <input type="date" defaultValue={defaultValue} data-id={id} id={id}
                            className={type}
                            min={HUNDRED_YEARS_AGO.toISOString() }
                            max={TODAY.toISOString() }/>
            break;
        case "date-of-birth":
            inputs = inputs || <input type="date" defaultValue={defaultValue} data-id={id} id={id}
                            className={type} defaultValue={defaultValue}
                            min={ subYears(TODAY, 120).toISOString() }
                            max={ subYears(TODAY, 18).toISOString() } />
            break;
        case "readonly":
            inputs = inputs || <input value={defaultValue || ''} data-id={id} id={id} readOnly={true} />
            break;
        default:
            inputs = inputs || <input defaultValue={defaultValue} data-id={id} id={id} />
    }
    const typeCss = type || ''

    return <div className={`form-field ${typeCss} ${className}`} key={id} ref={inRef} >
                <LabelWithMarkup className={`warning ${warning ? '' : 'off'}`} md={warning}/>
                <LabelWithMarkup htmlFor={id || ""} md={label}/>
                {inputs}
            </div>
}


const LabelWithMarkup = ({md, className, htmlFor}) => {
    try {
        const html = markdown(md || '')
        return <label htmlFor={htmlFor} className={className} dangerouslySetInnerHTML={{__html: html}}/>
    }catch(e){
        console.error("Markdown ERROR:", e)
        return <label htmlFor={htmlFor} className={className}>{md}</label>
    }
}

const ObjectToList = ({obj, title, recursion=5}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const toggle = (e) => {
        setIsExpanded(!isExpanded)
    }
    const objectToArray = (o, prefix = '', recursion = 5) => {
        const ar = []
        if(!o) return ar
        if(recursion < 0 || isNaN(recursion)) return ar
        Object.entries(o).forEach( ([key, val]) => {
            const label = prefix ? `${prefix}.${key}` : key
            const isSimple = (typeof val === 'string' || val instanceof String || !isNaN(val))
            const isBool = (typeof val === "boolean")
            if(isBool) {
                ar.push({label:label, val: val ? "YES" : "NO"})
            } else if(isSimple) {
                ar.push({label:label, val:val})
            } else {
                const subarr = objectToArray(val, label, recursion - 1)
                ar.push(...subarr)
            }
        })
        return ar
    }
    if(!obj || recursion <= 0) return null
    const list = objectToArray(obj).sort(compareBy('label')).map(
        (item, i) => {
            return <li key={i}><b>{item.label}:</b> <span>{item.val}</span></li>
        }
    )
    return <ul className={`objectToList ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <li onClick={toggle}><span>{title}</span><span >{isExpanded ? "...hide" : "...show"}</span></li>
        {isExpanded ? list : null}
    </ul>
}




export {LabelWithMarkup, FormField, FormSection, ObjectToList}