import {useRef, useState} from "react";
import './ospertest.css'
import ClevaAPI from "../../api/ClevaAPI";


const FormRow = ({label, name, childindex, handleChange, model}) => {
    const val = childindex === undefined ? model.adult[name] : model.children[childindex][name]
    return <div className='formRow' key={name + '-row'}>
        <label key={name + '-label'}>{label}</label>
        <input name={name} onChange={handleChange} value={val} key={name + childindex} data-child={childindex}/>
    </div>
}

const BirthDateRow = ({label, name, childindex, handleChange}) => {
    return <div className='formRow'>
        <label>{label} YYYY MM DD</label>
        <input name={name + '.year'} data-child={childindex} onChange={handleChange} maxLength="4"></input>
        <input name={name + '.month'} data-child={childindex} onChange={handleChange} maxLength="2"></input>
        <input name={name + '.day'} data-child={childindex} onChange={handleChange} maxLength="2"></input>
    </div>
}

const ChildRow = ({index, removeChild, handleChange, model}) => {
    return <div key={index} className="child">
        <FormRow name="first_name" label="first name" childindex={index} handleChange={handleChange} model={model} />
        <FormRow name="last_name" label="last name" childindex={index} handleChange={handleChange} model={model} />
        <FormRow name="email" label="email" childindex={index} handleChange={handleChange} model={model} />
        <FormRow name="mobile" label="mobile" childindex={index} handleChange={handleChange} model={model}/>
        <BirthDateRow name="birth_date" label="birth date" childindex={index} handleChange={handleChange} model={model}/>
        <FormRow name="gender" label="gender" childindex={index} handleChange={handleChange} model={model}/>
        <button onClick={removeChild} childindex={index} key={'remove-'+index}>remove child</button>
    </div>
}

const Kids = ({handleChange, removeChild, model}) => {
    return model.children ? model.children.map((c,i) => <ChildRow
            index={i}
            removeChild={removeChild}
            handleChange={handleChange}
            model={model}
            key={i}
        /> ) : "mo kids"
}

const Preview = ({model, api}) => {

    const [encrypted, setEncrypted] = useState("")
    const encrypt = async () => {
        const enc = await api.encrypt(model)
        setEncrypted(enc)
    }
    const url = 'https://osper.com/landing-page?ref_data_enc=' + encrypted + '&osp_source=osperforsquid&source=squid'
    return <div className="formRow">
        <JsonPreview model={model}/>
        <button onClick={encrypt}>encrypt</button>
        <textarea value={encrypted}></textarea>
        <a href={url} target="_blank">
            Open Osper Web 2
        </a>
    </div>
}

const JsonPreview = ({model}) => {
    return <textarea value={JSON.stringify(model, null, 2)}/>
}

const OsperOnboardingTest = () => {

    const [model, setModel] = useState({adult:{},children:[]})
    const api = ClevaAPI()

    const handleChange = (e) => {
        const name = e.target.name
        if(e.target.dataset.child !== undefined){
            const childIndex = parseInt(e.target.dataset.child)
            if(! ("children" in model))
                model.children = [{}]
            if(name.indexOf())
            assignWithDotNotation(model.children[childIndex], name, e.target.value)
            const m = JSON.parse(JSON.stringify(model))
            setModel(m)
        } else {
            assignWithDotNotation(model.adult, name, e.target.value)
            const m = JSON.parse(JSON.stringify(model))
            setModel(m)
        }
    }

    const assignWithDotNotation = (obj, dotnotation, value) => {
        const recursiveLookup = (obj, addr) => {
            if(addr.length === 1)
                obj[addr[0]] = value
            else {
                if(!( addr[0] in obj)) obj[addr[0]] = {}
                recursiveLookup(obj[addr[0]], addr.slice(1))
            }
        }
        const address = dotnotation.split('.')
        recursiveLookup(obj, address, value)
    }

    const addChild = () => {
        if(! ("children" in model))
            model.children = []
        model.children = model.children.concat({})
        setModel(JSON.parse(JSON.stringify(model)))
    }

    const removeChild = (e) => {
        var index = e.target.dataset.child
        var c = model.children
        c.splice(index, 1)
        setModel(JSON.parse(JSON.stringify(model)))
    }


    return <div className="osperOnboardingForm">
        <FormRow name="first_name" label="first name" handleChange={handleChange} model={model}/>
        <FormRow name="last_name" label="last name" handleChange={handleChange} model={model}/>
        <FormRow name="email" label="email" handleChange={handleChange} model={model}/>
        <FormRow name="postcode" label="postcode" handleChange={handleChange} model={model}/>
        <FormRow name="address1" label="address line 1" handleChange={handleChange} model={model}/>
        <FormRow name="address2" label="address line 2" handleChange={handleChange} model={model}/>
        <FormRow name="city" label="city" handleChange={handleChange} model={model}/>
        <FormRow name="mobile" label="mobile" handleChange={handleChange} model={model}/>
        <BirthDateRow name="birth_date" label="birth date" handleChange={handleChange} model={model}/>
        <FormRow name="gender" label="gender" handleChange={handleChange} model={model}/>
        <Kids model={model} removeChild={removeChild} handleChange={handleChange}/>
        <hr/>
        <button onClick={addChild}>add child</button>
        <Preview model={model} api={api}/>
    </div>
}

export default OsperOnboardingTest