export const ROOT = '/'
export const ROOT_SIGNUP = '/signup'
export const PREVIEW = 'preview'
export const MOBILE_VERIFICATION = 'mobile-verification'
export const DOCUMENT_UPLOAD = 'document-upload'
export const PAYMENT = 'payment'
export const CHECKOUT = 'checkout'
export const SUCCESS = 'success'
export const FAILURE = 'failure'
export const FAIL_HARD = 'fail-hard'
export const PAYMENT_FAIL = 'payment-fail'
export const KYC_PROGRESS = 'kyc-progress'
export const OSPER_TEST = 'osper-test'
export const B2B_ONBOARDING = 'B2B-onboarding'
export const B2B_ADMIN = 'B2B-admin'
export const B2B_KYC = 'B2B-KYC'







