import KybForm from "./kybForm";
import { useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ClevaAPI from "../../api/ClevaAPI";
import Logo from "../../components/logo";
import clevaAPI from "../../api/ClevaAPI";
import ReactDOMServer from "react-dom/server";
import userIcon from '../../assets/images/user.png'
import "./index.css"

const AvatarIcon = <svg className="avatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H322.8c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1H178.3zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L576.1 358.7l-71-71L375.9 417z"/>
</svg>

const B2BHeader = ({title, children}) => {
    return <header className="onboarding"><Logo />
        <h1>{title}</h1>
        <span className="spacer">&nbsp;</span>
        {children ? children : <img className="avatar" src={userIcon}/> }
    </header>
}

const B2BOnboarding = () => {
    const [ searchParams ] = useSearchParams();
    const [ user, setUser ] = useState()
    const ref = searchParams.get('ref')
    const api = ClevaAPI()
    const getUser = async () => {
        const u = await api.getUser()
        console.log("USER:", u)
        return u
    }
    useEffect( () => {
        getUser().then(u => setUser(u))
    }, [])

    const submit = () => {
        //const html = document.getElementById("root").outerHTML
        //const html = new XMLSerializer().serializeToString(document.getElementsByClassName("kyb-form")[0])
        const html = ReactDOMServer.renderToStaticMarkup(<KybForm applicationId={ref} user={user}></KybForm>)
        clevaAPI().submitForm(ref, html)
    }

    return <div className="wrapper onboarding">
        <B2BHeader title="Onboarding"/>
        <header>
            <p>Welcome to the Cleva KYB form. KYB is the due diligence process that we undertake to understand your
                business, the way you intend to use Cleva Cards and to verify the identities of those managing and in
                control of your business, as required by our issuing bank. We try to make this process as smooth as
                possible. If you have questions, comments, or feedback, please contact&nbsp;
                <a href="mailto:KYB@clevacard.com">KYB@clevacard.com</a></p>
        </header>
        <header className="smallprint">
            <small>ref: {ref}</small>
            <small>{user && user._email ? user._email : ''}</small>
        </header>
        {user ? <KybForm applicationId={ref} user={user}/> : "loading"}
        {/*<button value="submit" onClick={submit}>submit</button>*/}
    </div>
}

export default B2BOnboarding
export {B2BHeader}