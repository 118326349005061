import ClevaAPI from "../api/ClevaAPI";

const api = ClevaAPI()
let countries_list = {}
api.getCountries().then( l => {countries_list = l})

const asDict = (searchPhrase = null) => {
        const result = JSON.parse(JSON.stringify(countries_list))
        if(searchPhrase) {
            for(let code in result){
                if(code.indexOf(searchPhrase) < 0 && result[code].indexOf(searchPhrase) < 0)
                    delete result[code]
            }
        }
        return result
    }
const asList = (searchPhrase = null) => {
    const dict = asDict(searchPhrase)
    return Object.entries(dict)
        .map(([key, val]) => {return {key: key, value: val}})
        .sort( compare )
}
const asListAsync = async (searchPhrase = null) => {
    countries_list = await api.getCountries()
    return asList(searchPhrase)
}

const compare = ( a, b ) => {
  if ( a.value < b.value ) return -1
  if ( a.value > b.value ) return 1
  return 0
}


const Countries = {
        asDict:asDict,
        asList:asList,
        asListAsync:asListAsync
}

export default Countries