import {useEffect, useRef, useState} from "react";

const Camera = ({imageReadyCallback}) => {
    const videoRef = useRef()
    const canvasRef = useRef()
    const MODES = {LIVE:'live', SNAPSHOT:'snapshot'}
    const [mode, setMode] = useState(MODES.LIVE)
    let w = 2048 //1200
    let h = 1600 //1024
    const videoPrefs = {
        audio: false,
        video: {
            width: 2048,
            height: 1600
        }
    };

    const resizeCanvasAndVideo = (w, h) => {
        [canvasRef, videoRef].forEach( ref => {
            ref.current.setAttribute('width', w)
            ref.current.setAttribute('height', h)
        })
    }

    const getVideoSize = (stream) => {
        const mediaStream = stream.getTracks()[0]
        const mediaSettings = mediaStream.getSettings()
        console.log("mediasettings", mediaSettings, mediaSettings.width, mediaSettings.height)
        return [mediaSettings.width, mediaSettings.height]
    }

    const takePhoto = () => {
        const canvas = canvasRef.current
        const context = canvas.getContext("2d");
        context.fillStyle = "#AAA";
        console.log("takePhoto", w, h)
        context.fillRect(0, 0, w, h);
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        setMode(MODES.SNAPSHOT)
        stopVideo()
    }

    const tryAgain = () => {
        startCamera()
    }

    const startCamera = () => {
        setMode(MODES.LIVE)
        const v = videoRef.current
        navigator.mediaDevices
            .getUserMedia(videoPrefs)
            .then((stream) => {
                v.srcObject = stream;
                v.play();
                [w, h] = getVideoSize(stream)
                resizeCanvasAndVideo(w, h)
                console.log("size:", w, h)
            })
            .catch((err) => {
                console.error(`An error occurred: ${err}`);
            });
    }

    const accept = () => {
        const mime = 'image/jpeg'
        let file = null;
        canvasRef.current.toBlob(blob => {
            file = new File([blob], 'scan.jpg', { type: mime });
            if(imageReadyCallback) imageReadyCallback(file)
        }, mime);
    }

    const cancel = () => {
        stopVideo()
        imageReadyCallback(false)
    }

    const stopVideo = () => {
        if(videoRef && videoRef.current) {
            videoRef.current.srcObject.getTracks().forEach(track => {
                track.stop()
            })
        }
    }

    useEffect(() => {
        startCamera()
        return stopVideo
    }, [])

    return <div className="camera">
        {mode}
        <div className={`cameraBackdrop ${mode === MODES.SNAPSHOT ? 'hidden' : ''}`}>starting the camera...</div>
        <canvas ref={canvasRef} className={mode === MODES.LIVE ? 'hidden' : ''}></canvas>
        <video ref={videoRef} className={mode === MODES.SNAPSHOT ? 'hidden' : ''}>Video stream not available.</video>
        <div className="cameraButtons">
            <button onClick={cancel} className="auxiliary" >Cancel</button>
            <span>&nbsp;</span>
            <button onClick={takePhoto} className={mode === MODES.SNAPSHOT ? 'disabled' : ''}>Take photo</button>
            <button onClick={tryAgain} className={mode === MODES.LIVE ? 'disabled' : ''}>Try again</button>
            <button onClick={accept} className={mode === MODES.LIVE ? 'disabled' : ''} >Accept</button>

        </div>
    </div>
}

export default Camera