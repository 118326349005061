import React from 'react'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';




const FailHard = () => {

    const { t } = useTranslation();


    return (
        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                {/* <GetCleva /> */}
                <Grid container>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    <Grid item xs={12}
                        md={10}
                        lg={7}
                        xl={6}>
                        <div className="header">
                            <div className="heading">
                                <h3 className='heading_error'>{t('sorry')}</h3>
                            </div>
                            <h4 >{t('fail_hard_message')}</h4>
                            <h4 >{t('contact_us')}</h4>
                            


                        </div>
                    </Grid>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    {/* <Loader /> */}
                </Grid></Grid></div>
    )

}






export default FailHard
