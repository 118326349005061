import {post, get} from './index'

const ScanTypeNames = {
    ID: 'ID',
    POA: 'POA'
}
const FormStatus = {
    OPEN: 'open',
    SUBMITTED: 'submitted',
    APPROVED: 'approved'
}

const ClevaAPI = () => {

    const onboard = (data) => {
        post("/onboard", data)
    }
    const verifyMobile = (data) => {
        post("/onboard", data)
    }
    const getUser = () => {
        return get("/user")
    }
    const logout = () => {
        return get("/logout")
    }
    const storeCache = async (id, data) => {
        return await post("/cache/" + id, data)
    }
    const restoreCache = async (id, newerThan = 0) => {
        return await get("/cache/" + id + "?newer_than=" + newerThan)
    }
    const listCaches = async () => {
        return await get("/cache")
    }
    const getFormStatus = async (id) => {
        return await get("/cache/" + id + "/status")
    }
    const submitForm = async (id, html) => {
        const data = {status:FormStatus.SUBMITTED, doc:html}
        return await post("/cache/" + id + "/status", data)
    }
    const reopenForm = async (id, html) => {
        const data = {status:FormStatus.OPEN, doc:html}
        return await post("/cache/" + id + "/status", data)
    }
    const approveForm = async (id, html) => {
        const data = {status:FormStatus.APPROVED, doc:html}
        return await post("/cache/" + id + "/status", data)
    }
    const ekyc = async (cache_id, person) => {
        return await post("/ekyc/" + cache_id, person)
    }
    const reset_ekyc = async (cache_id, result_id, stage) => {
        return await get("/ekyc-reset/" + cache_id + "/" + result_id + "?stage=" + stage)
    }
    const ekycGetLast = async (cache_id, result_id) => {
        return await get("/ekyc/" + cache_id + "/" + result_id)
    }
    const ekycScansList = async (cache_id, result_id) => {
        return await get("/idscan/" + cache_id + "/" + result_id)
    }
    const uploadScan = async (cache_id, person_id, scanTypeName, file, onProgress) => {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('scan', file);
            let request = new XMLHttpRequest();
            request.responseType = 'json'
            request.open('POST', '/api/idscan/' + cache_id + '/' + person_id + '/' + scanTypeName);//

            request.upload.addEventListener('progress', function (e) {
                let percent_completed = (e.loaded / e.total) * 100;
                if (onProgress) onProgress(percent_completed)
            });

            request.addEventListener('load', function (e) {
                if (request.status >= 200 && request.status < 300) {
                    resolve(request.response);
                } else {
                    reject({
                        status: request.status,
                        statusText: request.statusText
                    });
                }
            })
            request.addEventListener('error', function (e) {
                reject({
                        status: request.status,
                        statusText: request.statusText
                    });
                }
            );

            request.send(data);
        })
    }
    const checkScanResult = async (cache_id, person_id, imgUrl = '', type = '') => {
        let url = "/idscan/" + cache_id + "/" + person_id + "/check?"
        if(imgUrl) url = url + "url=" + encodeURIComponent(imgUrl)
        if(type) url = url + "type=" + encodeURIComponent(type)
        return get(url)
    }
    const getCountries = async () => {
        return await get("/static-countries")
    }

    return {
        onboard: onboard,
        getUser: getUser,
        logout: logout,
        storeCache: storeCache,
        restoreCache: restoreCache,
        listCaches: listCaches,
        submitForm: submitForm,
        reopenForm: reopenForm,
        approveForm: approveForm,
        getFormStatus: getFormStatus,
        ekyc: ekyc,
        reset_ekyc: reset_ekyc,
        ekycGetLast: ekycGetLast,
        ekycScansList: ekycScansList,
        uploadScan: uploadScan,
        checkScanResult: checkScanResult,
        getCountries: getCountries
    }
}



export {ScanTypeNames, FormStatus}
export default ClevaAPI