import ClevaAPI, {FormStatus} from "../../api/ClevaAPI";
import React, {useEffect, useState} from "react";
import Cache from "../../utils/cache"
import {B2BHeader} from "../B2BOnboarding";
import './B2BAdmin.css'
import {compareBy} from "../../utils/commonUtils";

const B2BAdmin = () => {
    const [user, setUser] = useState()
    const [imageUrl, setImageUrl] = useState()
    const [orderByColumn, setOrderByColumn] = useState('updatedWhen')
    const [orderAscending, setOrderAscending] = useState(false)
    const [refs, setRefs] = useState([])
    const api = ClevaAPI()
    const cache = Cache()
    const getUser = async () => {
        const u = await api.getUser()
        console.log("USER:", u)
        setUser(u)
        if( !u.is_authenticated ){
            window.location.href='/api/login?next_url=' + encodeURI(window.location.href)
        }else{
            setImageUrl(u._picture)
            loadRefs()
        }
    }
    const loadRefs = async () => {
        const r = await api.listCaches()
        setRefs(r)
    }
    const uuidv4 = () => {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }
    const handleClickNewApp = async (e) => {
        const ref = uuidv4()
        const url = `/B2B-onboarding?ref=${ref}`
        cache.clear()
        const result = await cache.initNew(ref, {'company.name':'NEW'})
        window.location = url
    }


    useEffect( () => {
        getUser()
    }, [] )

    const onClickLogout = async (e) => {
        await api.logout()
        window.location.href="/"
        return false
    }


    const extractLatestUpdate = (f) => {
        let latestUpdate = {
            'timestamp':0
        }
        for(const key in f){
            console.log("extractLatestUpdate", key)
            try {
                if (f[key] && f[key].timestamp && (latestUpdate.timestamp < f[key].timestamp)) {
                    latestUpdate = Object.assign({}, f[key])
                    latestUpdate.key = key
                    latestUpdate.isodate = new Date(latestUpdate.timestamp).toISOString().replace('T', ' ').slice(0, 16)
                    latestUpdate.updatedBy = (latestUpdate.updatedBy || 'anonymous').split('@')[0]
                }
            }catch(e){
                console.error(e)
            }
        }
        return latestUpdate
    }

    const validationSummary = (item) => {
        if(!item.validation) return "N/A"
        if(!item.validation.val) return "N/A"
        if(!item.validation.val.length) return "OK"
        const msgs = item.validation.val
        let res = [`${item.validation.val.length} error(s)`]
        res = res.concat(msgs.map(item => item.id))
        return res.map( item => <span>{item}</span>)
    }

    const list = Object.entries(refs).map( ([ref, form]) => {
        const item = form.data
        const meta = form.meta
        const latestUpdate = extractLatestUpdate(form.data)
        item.ref = ref
        item.companyName = item['company.name'] ? item['company.name']['val'] : "N/A"
        item.jiraTicket = item['prefix'] ? item['prefix']['val'] : ""
        item.validationSummary = validationSummary(item)
        item.updatedBy = latestUpdate.updatedBy
        item.updatedWhen = latestUpdate.isodate
        item.formStatus = meta.status
        return item
    } )

    const sortTable = (e) => {
        const colName = e.target.dataset.sort
        if(!colName) return
        if(colName === orderByColumn) {
            setOrderAscending(!orderAscending)
        } else {
            setOrderAscending(false)
            setOrderByColumn(colName)
        }
    }

    const formToTableRow = (form) => {
        return <tr >
            <td>
                <a href={`/B2B-onboarding?ref=${form.ref}`}>
                    <b>{form.companyName}</b><br/>
                <small>{form.ref}</small>
                </a>
            </td>
            <td>{form.jiraTicket}</td>
            <td>{form.updatedWhen}</td>
            <td>{form.updatedBy}</td>
            <td>{form.formStatus} <StatusChangeButton form={form}/></td>
            <td><div className="validationSummary">{form.validationSummary}</div></td>
        </tr>
    }

    const StatusChangeButton = ({form}) => {

        const reopen = async () => {
            await api.reopenForm(form.ref)
            loadRefs()
        }
        switch (form.formStatus){
            case FormStatus.SUBMITTED: return <button onClick={reopen}>reopen</button>
            default: return ''
        }
        if(form.formStatus === 'open') return ''

    }

    list.sort( compareBy(orderByColumn, orderAscending))

    return <div className="wrapper">
        <B2BHeader title="B2B Admin">
            <button onClick={onClickLogout}>logout</button>
            <img className="avatar" src={imageUrl}/>
        </B2BHeader>
        {refs ?
        <table className="onboarding">
            <thead><tr onClick={sortTable}>
                <th data-sort="companyName">company</th>
                <th data-sort="jiraTicket">JIRA</th>
                <th data-sort="updatedWhen">last updated on</th>
                <th data-sort="updatedBy">last updated by</th>
                <th data-sort="status">status</th>
                <th>validation</th>
            </tr></thead>
            <tbody>
            {list.map(formToTableRow)}
            </tbody>
        </table> : <h3>no applications found</h3>
        }
        <button onClick={handleClickNewApp}>OPEN NEW APPLICATION</button>

    </div>
}

export default B2BAdmin