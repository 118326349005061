import './validation.css'
import { subYears } from "date-fns";
import KYC_STATUS from "../B2BKYC/constants";
const DOMAIN_REGEX = /^(?!.*?_.*?)(?!(?:[\d\w]+?\.)?\-[\w\d\.\-]*?)(?![\w\d]+?\-\.(?:[\d\w\.\-]+?))(?=[\w\d])(?=[\w\d\.\-]*?\.+[\w\d\.\-]*?)(?![\w\d\.\-]{254})(?!(?:\.?[\w\d\-\.]*?[\w\d\-]{64,}\.)+?)[\w\d\.\-]+?(?<![\w\d\-\.]*?\.[\d]+?)(?<=[\w\d\-]{2,})(?<![\w\d\-]{25})$/

const ValidatorsLibrary = () => {
    const mandatory = () => {
        return function mandatoryTest(v, label) {
            if(!v || v.length === 0){
                return {
                    msg:`${label} is mandatory`
                }
            }
        }
    }
    const alphanum = (minAlphaNumChars) => {
        return function alphanumTest(v, label) {
            const alphaChars = v.replaceAll(/[^A-Z0-9]/gi, '')
            if(alphaChars.length < minAlphaNumChars){
                return {
                    msg:`${label} needs at least ${minAlphaNumChars} letters or digits`
                }
            }
        }
    }
    const length = (min, max = 99999) => {
        return function lengthTest(v, label) {
            if(v.trim().length < min){
                return {msg: `${label} is too short, need at least ${min} characters`}
            }
            if(v.trim().length > max){
                return {msg:`${label} is too long, needs at least ${max} characters and has ${v.trim().length}`}
            }
        }
    }
    const words = (min, max = 1000) => {
        return function wordsTest(v, label) {
            const trimmed = v.trim()
            if(!trimmed.length) {
                return {msg: `${label} is mandatory, needs at least ${min} words.`}
            }
            const wordsCount = trimmed.split(' ').length
            if(wordsCount < min){
                return {msg: `${label} is too short, needs at least ${min} words, has ${wordsCount}`}
            }
            if(wordsCount > max){
                return {msg:`${label} is too long, needs at least ${max} words and has ${wordsCount}`}
            }
        }
    }
    const num = (min, max, optional ) => {
        return function numTest(v, label) {
            if(!v && optional) return
            if(isNaN(parseInt(v))){
                return {msg: `${label} needs to be a number between ${min} and ${max}`}
            }
            if(parseInt(v) < min){
                return {msg: `${label} needs to be at least ${min}`}
            }
            if(parseInt(v) > max){
                return {msg:`${label} needs to be at most ${max}`}
            }
        }
    }
    const date = (min, max) => {
        return function dateTest(v, label) {
            if(!v) {
                return {msg: `${label} is missing`}
            }
            if(!v.match(/\d\d\d\d-\d\d-\d\d/)) {
                return {msg: `${label} has to follow the YYYY-MM-DD format, now it's ${v}`}
            }
            const asDate = new Date(v)
            if(asDate < min){
                return {msg: `${label} can be only as old as ${min.toDateString()}`}
            }
            if(asDate > max){
                return {msg:`${label} cannot fall later than ${max.toDateString()}`}
            }
        }
    }
    const email = (mandatory=true) => {
        return function emailTest(v, label) {
            if(mandatory){
                if(!v || !v.trim()) {
                    return {msg: `${label} is mandatory`}
                }
            }
            if(v.indexOf('@') < 0){
                return {msg: `${label} is missing the @ sign: ${v}`}
            }
            const [user, domain] = v.trim().split('@', 2)
            if(user.indexOf(' ') >= 0){
                return {msg: `${label} has spaces in the mailbox name: "${user}"`}
            }
            if(!domain.match(DOMAIN_REGEX)){
                return {msg: `${label} the domain name (after @) does not look correct: "${domain}"`}
            }
        }
    }
    const telephone = (prefix = '', min = 5, max = 20) => {
        return function telephoneTest(v, label) {
            const onlyDigits = v.replaceAll(/[^0-9]/g, '')
            if(onlyDigits.length < min){
                return {msg: `${label} is too short, need at least ${min} digits and has ${onlyDigits.length}`}
            }
            if(onlyDigits.length > max){
                return {msg:`${label} is too long, needs at least ${max} digits and has ${onlyDigits.length}`}
            }
            const notAllowed = v.replaceAll(/[0-9+\-\. ]*/gi, '')
            if(notAllowed.length > 0){
                return {msg:`${label} seems to have some random extras: ${notAllowed}`}
            }
        }
    }
    const yesno = () => {
        return function yesnoTest(v, label) {
            if(!v){
                return {
                    msg:`${label} needs selected either option`
                }
            }
        }
    }
    const isOnTheList = (list) => {
        return function isOnTheListTest(v, label) {
            if(!v) return { msg:`${label} is mandatory and needs to be one of: ${list.join(', ')}` }
            if(list.indexOf(v.toLowerCase()) < 0){
                return {
                    msg:`${label} needs to be one of: ${list.join(', ')}`
                }
            }
        }
    }
    const kycform = () => {
        return function kycformTest(v, label) {
            if(!v){
                return {
                    msg:`The person identity and address need to be confirmed. Please use the "check" button.`
                }
            }
        }
    }
    const kycStatus = () => {
        const COMPLETE_STATES = [KYC_STATUS.PASS, KYC_STATUS.FAIL]
        return function kycStatusTest(v, label) {
            console.log("validating kycStatus: ", label, v)
            if(!v || v === KYC_STATUS.EKYC){
                return {
                    msg:`The person's identity and address need to be confirmed. Please click the link and complete the form.`
                }
            }
            if(v === KYC_STATUS.SCAN){
                return {
                    msg:`Please provide documents to confirm the identity and address.`
                }
            }
            if(!COMPLETE_STATES.includes(v)){
                return {
                    msg:`Please try again to submit the personal details.`
                }
            }
        }
    }
    const jira = (optional = false) => {
        return function jiraTest(v, label) {
            if(!v && optional){
                return
            }
            if(!v){
                return {
                    msg:`The JIRA ticket or an unique prefix for cardholder refs is mandatory`
                }
            }
            if(!v.match(/[A-Za-z]{1,5}\-\d{1,8}/)){
                return {
                    msg:`The ${label} has to to follow the XXXX-0000 format, it is "${v}" now`
                }
            }
        }
    }
    const cardUsageMatrix = () => {
        return function cardUsageMatrixTest(v, label) {
            if(!v) return { msg:`${label} is sort fo mandatory too.`}
            for(let cellName in v){
                let cellVal = v[cellName]
                let err
                if(cellName.endsWith('card_num')){
                    err = num(1,999999)(cellVal, `number of cards`)
                    if(err) return err
                }
                if(cellName.endsWith('avg_load')){
                    err = num(1,9999)(cellVal, `average load`)
                    if(err) return err
                }
                if(cellName.endsWith('load_freq')){
                    err = isOnTheList(['daily','weekly','monthly','other'])(cellVal, "average load frequency")
                    if(err) return err
                }
            }
            console.log("all good!")
        }
    }
    const allChecked = (minChecked) => {
        return function allCheckedTest(v, label) {
            if (!v || v.length < minChecked) return {msg: `${label} - ${minChecked} items have to be checked.`}
        }
    }
    const ukPostCode = () => {
        return function ukPostCodeTest(v, label) {
            if(!v) return {msg: `${label} is mandatory.`}
            if(v.trim().indexOf(' ') < 0) return {msg: `Missing space in the ${label}`}
            //var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
            var postcodeRegEx = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;
            if(!postcodeRegEx.test(v)) return {msg: `${label} does not look like a valid UK post code.`}
        }
    }

    return {
        mandatory: mandatory,
        length:length,
        words:words,
        alphanum:alphanum,
        num:num,
        date:date,
        email:email,
        telephone:telephone,
        yesno: yesno,
        kycform: kycform,
        kycStatus: kycStatus,
        cardUsageMatrix: cardUsageMatrix,
        jira: jira,
        allChecked: allChecked,
        ukPostCode: ukPostCode
    }
}

const Validators = ValidatorsLibrary()

const validateFormField = (field) => {
    let validators = []
    switch(field.type){
        case "warning": field.optional=true; break;
        case "file":break;
        case "list":break;
        case "kyc-status":
            validators.push(Validators.kycStatus())
            break;
        case "kyc-form":
            validators.push(Validators.kycform(field))
            break;
        case "yesno":
            validators.push(Validators.yesno())
            break;
        case "matrix":break;
        case "checklist":break;
        case "checklist-with-others":break;
        case "select":break
        case "date":
            validators.push(Validators.date(subYears(new Date(), 120), new Date()))
            break;
        case "date-of-birth":
            validators.push(Validators.date(subYears(new Date(), 120), subYears(new Date(),18)))
            break;
        case "percentage":
            break;
        case "person":
            break;
        case "telephone":
            validators.push(Validators.telephone())
            break;
        case "email":
            validators.push(Validators.email())
            break;
        case "textarea":
        default:
            // nada

    }
    //console.log("FIELD: ", field)
    if(field.validators) validators.push(...field.validators);
    if(!field.optional && !validators.length) validators.push(Validators.mandatory())

    const [_, val] = getElementAndValue(field.id)
    return validators.map(v => {
        const labelShort = ellipsis(field.label, 60)
        //if(field.type==='select') debugger
        return {...field, ...v(val, labelShort)}
    }).filter( err => {return err.msg} )
}


const ellipsis = (label, max = 100) => {
    if(!label) return ''
    if(label.length <= max) return label
    return label.substr(0, max).split(' ').slice(0,-1).join(' ') + '...'
}

const ValidationRunner = () => {
    const fields = []
    const clear = () => {
        fields.length = 0
    }
    const addRule = (field) => {
        if(!fields.some(f => f.id === field.id)) {
            fields.push(field)
        }
        return fields.length - 1
    }
    const run = () => {
        const errors = []
        for (const field of fields) {
            if (field.validators || field.type) {
                errors.push(...validateFormField(field))
            }
        }
        return errors
    }

    return {
        run:run,
        addRule:addRule,
        clear:clear
    }
}

const getElementAndValue = (fieldId) => {
    let els = Array.from(document.querySelectorAll("[data-id]"))
        .filter(el => {
            return el.dataset.id.endsWith(fieldId)
        })
    let val
    if (els.length < 1) {
        return [false, false]
    } else if (els.length > 1) {
        // radio buttons
        const selectedEl = els.filter(el => el.checked)
        if (selectedEl.length) val = selectedEl[0].value
    } else if (els[0].classList.contains('checklist')) {
        let checks = els[0].querySelectorAll([':checked'])
        val = Array.from(checks).map(check => check.dataset.id)
    } else if (els[0].classList.contains('matrix')) {
        let inputs = els[0].querySelectorAll(['input, select'])
        val = Array.from(inputs).reduce((acc, input) => {acc[input.dataset.id] = input.value; return acc}, {})
    } else {
        val = els[0].value
    }
    return [els[0], val]
}



const Pointer = ({target}) => {
    if(!target) return null
    let tempTarget = target
    let style, bb
    do {
        try {
            bb = tempTarget.getBoundingClientRect()
            style = {
                left: (bb.left - 45 + window.scrollX) + 'px',
                top: (bb.top + bb.height / 2 - 10 + window.scrollY) + 'px'
            }
            console.log(tempTarget, style)
            tempTarget = tempTarget.parentElement
        }catch(e){
            console.error(e)
        }

    } while(bb.left < 0 && tempTarget)
    //console.log(style)
    return <div className="pointer" style={style} ></div>
}


const ValidationUI = ({problems, onProblemClick}) => {
    if (problems && problems.length > 0) {
        return <div className="problems">
            <ol> {problems.map(
                (p, i) => <li className="error" key={i} onClick={onProblemClick} data-fieldid={p.id}>{p.msg}</li>
            )}
            </ol>
        </div>
    } else {
        return null
    }
}

export {Validators, ValidationRunner, ValidationUI, Pointer}