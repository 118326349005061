const FocusUtils = () => {

  const KEYS = {
    BACKSPACE:    8,
    TAB:          9,
    ENTER:        13,
    LEFT_ARROW:   37,
    RIGHT_ARROW:  39,
    ZERO:         48,
    NINE:         57,
    NUM_ZERO:     96,
    NUM_NINE:     105
  }
  let allInputs = window.document.getElementsByTagName("input")

  const indexOf = (input) => {
    let index;
    for (index = 0; allInputs[index] !== input && index < allInputs.length; index++) { }
    return index;
  }
  const isCompleted = (input) => {
    return (input.maxLength > 0 && input.value.length >= input.maxLength)
  }
  const focusNext = (current) => {
    const nextInputIndex = indexOf(current) + 1
    if (nextInputIndex < allInputs.length) focusAndSelect(allInputs[nextInputIndex])
  }
  const focusPrev = (current) => {
    const nextInputIndex = indexOf(current) - 1
    if (nextInputIndex >= 0) focusAndSelect(allInputs[nextInputIndex])
  }
  const focusAndSelect = (el) => {
    el.focus()
    el.select()
  }
  const isNumeric = (key) => {
    return (key >= KEYS.ZERO     && key <= KEYS.NINE) ||
           (key >= KEYS.NUM_ZERO && key <= KEYS.NUM_NINE)
  }
  const isRightArrow = (key) => {
    return (key === KEYS.RIGHT_ARROW)
  }
  const isBackspace = (key) => {
    return (key === KEYS.BACKSPACE)
  }
  const isCursorMove = (key) => {
    return (
      [KEYS.BACKSPACE, KEYS.LEFT_ARROW, KEYS.RIGHT_ARROW, KEYS.TAB].includes(key)
    );
  }

  return {
    focusNext: focusNext,
    focusPrev: focusPrev,
    isBackspace: isBackspace,
    isNumeric: isNumeric,
    isCompleted: isCompleted,
    isRightArrow: isRightArrow,
    isCursorMove: isCursorMove,
    KEYS: KEYS
  }
}

export default FocusUtils
