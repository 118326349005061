import * as React from "react";
import { Route, Routes } from 'react-router-dom';
import * as PATHS from './CONSTANTS'
// import Home from '../pages/home/index'
import MobileVerification from '../pages/mobileVerification/index'
import DocumentUpload from '../pages/documentUpload/index'
import Payment from '../pages/payment';
import Thankyou from '../pages/thankyou';
import CheckOut from '../pages/payment/checkout';
import FailHard from '../pages/kycFailHard';
import PaymentFail from '../pages/paymentFail';
import KycProgress from '../pages/kycProgress';
import Preview from '../pages/preview';
import OsperOnboardingTest from '../pages/ospertest';
import B2BOnboarding from "../pages/B2BOnboarding";
import B2BAdmin from "../pages/B2BAdmin";
import B2BKYC from "../pages/B2BKYC";
const Home = React.lazy(() => import("../pages/home/index"));


const RouterConfig = () => {
    return (
        <Routes>
            <Route path={PATHS.ROOT} exact element={<Home />} />
            <Route path={PATHS.ROOT_SIGNUP} exact element={<Home />} />
            <Route path={PATHS.PREVIEW} exact element={<Preview />} />
            <Route path={PATHS.MOBILE_VERIFICATION} exact element={<MobileVerification />} />
            <Route path={PATHS.DOCUMENT_UPLOAD} exact element={<DocumentUpload />} />
            <Route path={PATHS.PAYMENT} exact element={<Payment />} />
            <Route path={PATHS.CHECKOUT} exact element={<CheckOut />} />
            <Route path={PATHS.SUCCESS} exact element={<Thankyou />} />
            <Route path={PATHS.FAIL_HARD} exact element={<FailHard />} />
            <Route path={PATHS.PAYMENT_FAIL} exact element={<PaymentFail />} />
            <Route path={PATHS.KYC_PROGRESS} exact element={<KycProgress />} />
            <Route path={PATHS.B2B_ONBOARDING} exact element={<B2BOnboarding />} />
            <Route path={PATHS.B2B_ADMIN} exact element={<B2BAdmin />} />
            <Route path={PATHS.B2B_KYC} exact element={<B2BKYC />} />
            <Route path={PATHS.OSPER_TEST} exact element={<OsperOnboardingTest />} />
        </Routes>
    )
}

export default RouterConfig
