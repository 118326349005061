


export const keyPressCommon = (e, focus) => {
    if (focus && (
        e.keyCode === focus.KEYS.ENTER || (
            e.target.type === 'number' &&
            !focus.isNumeric(e.keyCode) &&
            !focus.isCursorMove(e.keyCode)
        ))) {
        e.preventDefault()
    }
}
export const compareBy = (fieldName, ascending = true) => (a, b) => {
        if(a[fieldName] > b[fieldName]) return ascending ? 1 : -1
        if(a[fieldName] < b[fieldName]) return ascending ? -1 : 1
        return 0
    }
export const GenderOptions = [{ value: "FEMALE", label:'female' }, { value: "MALE", label:'male' }]

