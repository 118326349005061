import React from "react";

const Logo = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 784.74 240.18">
      <g data-name="Layer 2">
        <g fill="currentColor" data-name="Layer 1">
          <path
            d="M592.93 97.16a43 43 0 00-60.74 0l-5.76 5.53 32.74 37.36c8.83 8.46 15.37 18.77 27.59 18.16l6.18-.31c.56-.63 2.59-3.28 3.12-3.93 12.58-17.46 12.44-41.24-3.13-56.81z" />
          <path
            d="M502.92 97.16a43 43 0 00-60.74 0c-16.77 16.77-15.67 43.08 0 60.74l75.38 82.28 75.38-82.28-6.18.31a44.43 44.43 0 01-33-12.31zM331.83 161.61V127.3c.23-21 16.12-35.23 31-34.19-12.41-.41-20.32 13.63-20.54 34.19v34.31h66.57c0-1.55-.06-3.09-.15-4.62 0-.47-.07-.93-.11-1.39-.09-1.1-.19-2.19-.32-3.28l-.18-1.45c-.17-1.21-.37-2.4-.59-3.59-.06-.29-.1-.59-.16-.88q-.45-2.23-1-4.41l-.24-.85c-.32-1.18-.67-2.34-1.05-3.49l-.39-1.16c-.39-1.13-.81-2.25-1.25-3.35-.11-.28-.22-.57-.33-.84q-.87-2.11-1.86-4.16c-.66-1.36-1.36-2.71-2.1-4l-.36-.62c-.65-1.14-1.33-2.27-2-3.37l-.36-.56a76.68 76.68 0 00-8.61-10.88l-.23-.24a78.46 78.46 0 00-10.65-9.28q-1.93-1.4-4-2.68l-.19-.12A77 77 0 10330 238.61a76.73 76.73 0 0038.28-11.26 77.23 77.23 0 01-36.45-65.74zM671.91 91.35c-14.86 5.92-20.33 32.36-4.18 49.69 0 0 10.4 14.38 52.84 43.11L677 137.59c-16.87-20.87-5.65-45.39 13.5-49.76 0 0 17.9-4.16 43.07 5.71a29.05 29.05 0 0151.19 18.83v97.84a29 29 0 01-50 20.12 77.07 77.07 0 01-90.56-13.61c-30.09-30.09-29.71-78.5 0-109 4.83-5 13.33-10.65 27.38-16.7zM77.15 238.47a72.68 72.68 0 0045.5-15.92c13.74-11 5.77-33.18-11.81-33.18H82.4c1.89 5.1 5.65 14.11 26.36 25.86-37.13-9.06-44.42-42.2-44.42-54.72-.9-13.94 11.92-26.83 25.66-26.83h20.84c17.58 0 25.55-22.21 11.81-33.18a72.68 72.68 0 00-45.5-15.92 76.95 76.95 0 100 153.89zM167.83 209.51a29 29 0 0057.94 0V94.79c0 5.6-9.22 13.09-17.46 23.14-7.28 8.88-33.9 39.43-35.58 67.07 0-39.95 28.3-72.52 35.58-81.39 8.24-10 17.17-21 17.46-34V57.93A57.93 57.93 0 00167.84 0v209.51z" />
        </g>
      </g>
    </svg>
}

export default Logo