import {useEffect, useState} from "react";
import {STATES} from "../../utils/cache";

const StatusIndicator = ({savingStatus}) => {
    const [visible, setVisible] = useState('')
    let label;
    switch(savingStatus){
        case STATES.DIRTY: label = "changed"; break
        case STATES.SAVING: label = "saving"; break
        case STATES.SAVED: label = "saved"; break
        case STATES.LOADING: label = "loading"; break
        case STATES.LOADED: label = "loaded"; break
        case STATES.SAVE_ERROR: label = "could not save changes"; break
        case STATES.LOAD_ERROR: label = "could not load data"; break
        default: label = "unknown saving status: " + savingStatus
            debugger
    }

    useEffect( () => {
            setVisible('visible')
            const timer = setTimeout(() => {
                setVisible('')
            }, 5000)
            return () => clearTimeout(timer)
        },
        [savingStatus]
    )

    return <div className={`savingStatus ${savingStatus} ${visible}`}>{label}</div>
}


export {STATES}
export default StatusIndicator