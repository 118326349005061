import ClevaAPI from "../api/ClevaAPI";


const STATES = {
    DIRTY:  'dirty',
    SAVED:  'saved',
    SAVING: 'saving',
    SAVE_ERROR:  'save-error',
    LOADING: 'loading',
    LOADED: 'loaded',
    LOAD_ERROR: 'load-error'
}

const Cache = () => {
    let prefix = "local"
    let ref = ""
    let scheduleTimer;
    let remoteData;
    let callbacks = [];
    let onItemChangeCallbacks = [];
    let state;
    let status; // the form status - 'open', 'submitted' or 'approved'
    let lastSave = new Date()
    let lastLoad = false
    let latestUpdateTimestamp = 0
    let latestLocalUpdateTimestamp = 0

    const api = ClevaAPI()

    const getTimestamp = () => {
        return new Date().getTime()
    }
    const addMeta = (val) => {
        return {
            val:val,
            timestamp:getTimestamp(),
            updatedBy:''
        }
    }
    const schedulePost = () => {
        setState(STATES.DIRTY)
        if(scheduleTimer){
            clearTimeout(scheduleTimer)
        }
        scheduleTimer = setTimeout(sendNow, 2000)
    }
    const listToDict = (list) => {
        return Object.assign({}, ...list.map(([key, val]) => ({[key]: val})))
    }
    const clear = () => {
        localStorage.clear()//TODO: clear older than latest remotely saved item
    }
    const sendNow = async () => {
        if(scheduleTimer){
            clearTimeout(scheduleTimer)
        }
        console.log("sending cache")
        const nextLastSave = new Date()
        setState(STATES.SAVING)
        const form = {
            data: listToDict(entries(lastSave))
        }
        //WARNING: Some changes may get introduced while storeCache() is in progress and they would get lost
        const res = await api.storeCache(ref, form)
        console.log("POST result:", res)
        if(res === "OK") {
            setState(STATES.SAVED)
            lastSave = nextLastSave
        } else {
            setState(STATES.SAVE_ERROR)
        }
        return res
    }
    const loadRemote = async (newerThan=-1) => {
        if(newerThan < 0) newerThan = latestUpdateTimestamp
        const f = await api.restoreCache(ref, newerThan)
        const updates = []
        if(f.data) {
            remoteData = f.data
            for(const key in remoteData) {
                let val = remoteData[key]
                if(! val.hasOwnProperty('val'))
                    val = {'val':val, timestamp:0, updatedBy:''}
                if(storeIfNewer(key, val)) updates.push([key, val])
            }
            updateLatestTimestamp(remoteData)
            lastLoad = updates
        } else {
            lastLoad = false
        }
        return lastLoad
    }
    const updateLatestTimestamp = (data) => {
        Object.entries(data).forEach( ([ key, meta ]) => {
            if(meta.timestamp > latestUpdateTimestamp)
                latestUpdateTimestamp = meta.timestamp
        })
    }
    const storeIfNewer = (key, val) => {
        const localItem = getItem(key)
        if(
            // changes from other clients
            (val.timestamp > localItem.timestamp) ||
            // changes from another window, same session
            (val.timestamp === localItem.timestamp && val.timestamp > latestLocalUpdateTimestamp)
        ) {
            setItem(key, val, true)
            return true
        } else {
            //console.log("ignored ", key, val, " as older by secs: ", (localItem.timestamp - val.timestamp) * 0.001)
            return false
        }
    }
    const init = (refKey) => {
        ref = refKey
        prefix = `ref-${refKey}.`
    }
    const initNew = async(refKey, keyValueData = {}) => {
        init(refKey)
        for(let key in keyValueData){
            setItem(key, keyValueData[key])
        }
        sendNow()
    }
    const setRef = async (refKey) => {
        init(refKey)
        setState(STATES.LOADING)
        const result = await loadRemote()
        if(result) setState(STATES.LOADED)
        else setState(STATES.LOAD_ERROR)
    }
    const getRef = () => {
        return ref
    }
    const checkStatus = async () => {
        try {
            const res = await api.getFormStatus(ref)
            status = res.status
        } catch (e) {
            console.error(e)
        }
        return status
    }
    const removeListItem = (setkey, index) => {
        const keyToRemove = `${setkey}.${index}`
        const listSize = parseInt(getItem(setkey).val)
        if(isNaN(listSize)) throw new Error("error getting the length of the list of " + setkey)
        console.log("removeListItem", setkey, index, listSize)
        const allKeys = entries().map(([key, val]) => `${key}`)
        const isLastItem = listSize - 1 === index
        if(isLastItem){
            allKeys
                .filter( key => key.startsWith(keyToRemove) )
                .forEach( key => {removeItem(key)} )
            setItem(setkey, index)
        } else {
            // shift next element's values to the current one
            const keyNext = `${setkey}.${index+1}`
            allKeys
                .filter( key => key.startsWith(keyToRemove) )
                .forEach( key => {
                    const fieldId = key.replace(keyToRemove, '')
                    const val = getItem(`${keyNext}${fieldId}`)
                    setItem(key, val)
                })
            removeListItem(setkey, index + 1)
        }
    }
    const setItem = (key, info, noRemoteUpdate=false) => {
        if(info === undefined) throw new Error("Assigning undefined value to the key: " + key)
        if(!info.hasOwnProperty('val')) info = addMeta(info)
        if(getItem(key).val !== info.val) {
            localStorage.setItem(`${prefix}${key}`, JSON.stringify(info))
            latestLocalUpdateTimestamp = info.timestamp
            if(!noRemoteUpdate) schedulePost()
            triggerItemChangedCallbacks(key, info)
        }
    }
    const getItem = (key) => {
        try{
            let item = JSON.parse(localStorage.getItem(`${prefix}${key}`))
            if(item.hasOwnProperty('val'))  return item
            else return addMeta(item)
        } catch {
            return {
                val: localStorage.getItem(`${prefix}${key}`),
                timestamp: 0,
                updatedBy: ''
            }
        }

    }
    const removeItem = (key) => {
        const res = localStorage.removeItem(`${prefix}${key}`)
        schedulePost()
        return res
    }
    const entries = (newerThan = null) => {
        console.log("entries newer than:", newerThan)
        const allObjects = Object.keys(localStorage)
        const prefixed = allObjects.filter(
            (key) => key.startsWith(prefix)
        )
        const nonPrefixed = prefixed.map(
            (key)  => key.replace(prefix, '')
        )
        const withInfo = nonPrefixed.map(
            (key)  => [key, getItem(key)]
        )
        const newer = withInfo.filter(
            ([key, info]) => !(newerThan && (info['timestamp'] < newerThan.getTime()))
        )
        //console.log("ENTRIES:",prefixed, nonPrefixed, withInfo, newer)
        return newer
    }
    const exists = () => {
        if(!ref) throw new Error("No cache REF set yet")
        return lastLoad
    }
    const triggerItemChangedCallbacks = (key, info) => {
        if(onItemChangeCallbacks){
            onItemChangeCallbacks.forEach((callback) => {
                try {
                    callback(key, info)
                } catch (e) {
                    console.error(e)
                }
            })
        }
    }
    const setState = (s) => {
        state = s
        if(callbacks){
            callbacks.forEach((callback) => {
                try {
                    //console.log('calling back with ' + s)
                    callback(s)
                } catch (e) {
                    console.error(e)
                }
            })
        }
    }
    const setStatusCallback = (fn) => {
        if(fn) callbacks = [fn]
        else callbacks = []
    }
    const addStatusCallback = (fn) => {
        callbacks.push(fn)
    }
    const removeStatusCallback = (fn) => {
        callbacks = callbacks.filter(cb => cb !== fn)
    }
    const addItemChangeCallback = (fn) => {
        onItemChangeCallbacks.push(fn)
    }
    const removeItemChangeCallback = (fn) => {
        onItemChangeCallbacks = onItemChangeCallbacks.filter(cb => cb !== fn)
    }
    const handleChangeEvent = (e) => {
        if(e.target.type === 'checkbox') {
            setItem(e.target.dataset.id, e.target.checked ? "true" : "false")
        } else {
            setItem(e.target.dataset.id, e.target.value)
        }
    }

    return {
        initNew: initNew,
        clear: clear,
        setRef: setRef,
        getRef: getRef,
        setItem: setItem,
        getItem: getItem,
        removeItem: removeItem,
        removeListItem: removeListItem,
        entries: entries,
        loadRemote: loadRemote,
        sendNow: sendNow,
        exists: exists,
        checkStatus: checkStatus,
        setStatusCallback: setStatusCallback,
        addStatusCallback: addStatusCallback,
        removeStatusCallback: removeStatusCallback,
        handleChangeEvent: handleChangeEvent,
        addItemChangeCallback: addItemChangeCallback,
        removeItemChangeCallback: removeItemChangeCallback,
    }
}

export {STATES}
export default Cache


export const restoreCachedData = (updates) => {
    //console.log("restoreCachedData", updates)
    const inputElements = document.querySelectorAll("[data-id]")
    const affectedElements = []
    for (const [key, info] of updates) {
        const matching = Array.from(inputElements).filter(el => el.dataset.id === key)
        const value = info.val
        const timestamp = new Date(info.timestamp)
        const updatedBy = info.updatedBy || 'anonymous'
        const updatedByMsg = `updated by ${updatedBy} at ${timestamp}`
        //console.log("updating:", key, info)
        affectedElements.push(...matching)
        matching.forEach(el => {
            el.classList.add("updatedByOthers")
            switch (el.type) {
                case "checkbox":
                    el.checked = (["on", "true", "yes"].includes(value));
                    break;
                case "radio":
                    el.checked = (value === el.value);
                    break;
                default:
                    el.value = value
            }
            el.title = updatedByMsg

            if(el.classList.contains('autoclick')) {
                //console.debug("clicking ", el)
                el.click()
            }
        })
    }
    delayedRemoveClass(affectedElements)
}

const delayedRemoveClass = (elements) => {
    setTimeout(() => {
        elements.forEach( el => {
            el.classList.remove('updatedByOthers')
        })
    }, 5000)
}

export const setupPollingOnUserActivity = (pollFn) => {
    let pollingTimer
    const delay = 3000
    const doSinglePoll = () => {
        if(pollingTimer) {
            pollingTimer = false
            pollFn()
        }
    }
    const pollForUpdates = () => {
        if (pollingTimer) {
            // do nothing, the timer is on already
        } else {
            pollingTimer = setTimeout(doSinglePoll, delay)
        }
    }
    const activityEvents = ['mousemove','keydown']
    activityEvents.forEach( ev => document.addEventListener(ev, pollForUpdates) )
    return () => {
        clearTimeout(pollingTimer)
        activityEvents.forEach( ev => document.removeEventListener(ev, pollForUpdates) )
    }
}