import React from 'react';
import Button from '@material-ui/core/Button';
import './index.css'

function AuxButton(props) {
  return <DefaultButton className="auxButton" {...props} color='grey' />
}

function DefaultButton(props) {
  const { onClick, disabled, name, id } = props
  let styleClass = props.className ? props.className : "defaultButton"

  return (
      <Button variant="contained" className={styleClass} onClick={onClick}
        disabled={disabled} id={id} type="submit">
        {name}
      </Button>
  );
}

function NavButton(props) {
  const { onClick, disabled, name, id } = props
  return <Button className='defaultButton navButton' onClick={onClick}
    disabled={disabled} id={id}>
    {name}
  </Button>
}

export { DefaultButton, AuxButton, NavButton };

