const BASE_URL = '/api'
const API_VERSION = '0.0.1'

const fetchApi = async (path, init, blockUI = true) => {
    try {
        const response = await fetch(BASE_URL + path, init);
        let data = {}
        try {
            data = await response.json()
        } catch (e) {
        if (response.status === 204 || response.status === 200)
          data = { data: 'Success' }
        else
          data = { error: response.status }
        }
        return data
    } catch (e) {
        return { error: e.toString() }
    }
}

export async function get(path, blockUI = true) {
  const data = await fetchApi(
    path,
      {
      method: 'GET',
      headers: {
        'API-VERSION': API_VERSION
      }
    },
    blockUI
  );
  return data
}

export async function post(path, body, blockUI = true) {
  const data = await fetchApi(
    path,
      {
      method: 'POST',
      headers: {
        'API-VERSION': API_VERSION,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    },
    blockUI
  );
  return data
}
