import {Validators} from './validation'
import {subYears} from "date-fns";
const V = Validators

const TODAY = new Date()
const YEAR_1900 = new Date('1900-01-01')
const template = (countries) => {
    return {
        meta: {
            fields: [
                {label: "JIRA/prefix:", id: "prefix", validators: [V.jira(true)]}
            ],
        },
        sections: [
            {
                id: "PrincipalDetails",
                title: "Company & Principal Contact",
                fields: [
                    {label: "Company Name", id: "company.name", validators: [V.alphanum(3), V.length(3, 200)]},
                    {label: "Trading Name or also known as", id: "company.trading_name", validators: [V.alphanum(0), V.length(0, 200)]},
                    {label: "Member of a group:", id: "company.group_name", validators: [V.alphanum(0), V.length(0, 200)]},
                    {label: "Company Number", id: "company.number", validators: [V.length(3)]},
                    {label: "Date of Incorporation", id: "company.incorporation_date",
                        type: "date", validators: [V.date(YEAR_1900, TODAY)]},
                    {
                        label: "Country of Incorporation",
                        id: "company.incorporation_country",
                        default: "England and Wales"
                    },
                    {label: "Registered Office Address", id: "company.registered_office", optional:true},
                    {label: "Principal place of business (if different)", id: "company.principal_place"},
                    {label: "Company Website", id: "company.website"},
                    {label: "Name of Principal Contact", id: "company.principal_contact", type: "person"},
                    {label: "Telephone Number", id: "company.telephone", type: "telephone"},
                    {label: "E-mail Address", id: "company.email", type: "email"},
                ]
            },
            {
                id: "AboutBusiness",
                title: "About your business",
                fields: [
                    {
                        label: "Please describe in detail your company’s day to day business activities:",
                        id: "company.activities",
                        type: "textarea",
                        validators: [V.words(5)]
                    },
                    {
                        label: "Please indicate for what purpose the cards will be used for:",
                        id: "company.cards_purpose",
                        type: "checklist-with-others",
                        options: [
                            {label: "Home care agency", id: "home-care-agency"},
                            {label: "Case Management/Deputies", id: "management-deputies"},
                        ]
                    },
                    {
                        label: "Will the company identified above be loading the cards with funds?",
                        id: "company.same_funding",
                        type: "yesno"
                    },
                    {
                        label: "Please describe the process you intend to use for providing your employees with cards. (E.g. will an agency representative set up each user and require cards to be collected in person?) Please provide details of the process.",
                        id: "company.provision_process",
                        type: "textarea",
                        validators: [V.words(5, 1000)]
                    },
                    {
                        label: "Do you have an existing prepaid card solution? If so, please provide details:",
                        id: "company.existing_prepaid_solution",
                        type: "textarea",
                        optional:true
                    },
                ]
            },
            // {
            //     id: "RiskAndRegulations",
            //     title: "Risk & Regulation",
            //     fields: [
            //         { label:"What do you consider to be the main fraud, money laundering and financial crime risks associated with the cards?", id:"riskandreg.main_risks", type:"textarea" },
            //         { label:"Please describe how you intend to control and mitigate the risks outlined above:", id:"riskandreg.mitigation", type:"textarea" },
            //         { label:"Please confirm whether or not you have completed a risk assessment for the program. If a risk assessment has been completed, please provide details or a copy:", id:"riskandreg.assessment", type:"textarea" },
            //         { label:"Please confirm if your company is:", id:"riskandreg.compliance_checks",
            //             type:"checklist", options:[
            //                 {label:"Registered for Data Protection",id:"data-protection"},
            //                 {label:"Licensed with a regulator",id:"licensed-with-regulator"},
            //                 {label:"PCI compliant",id:"pci-compliant"},
            //             ] },
            //         { label:"If you have answered yes to any of the above, please provide details of the number, country and date of registration/license.  Also provide any other bodies or authorities that may regulate your company:", id:"riskandreg.compliance_details", type:"textarea" },
            //     ]
            // },
            {
                id: "Requirements",
                title: "Your requirements",
                fields: [
                    {
                        label: "Please confirm that all cardholders will be residents of the UK",
                        id: "requirements.uk_residents_only",
                        type: "yesno"
                    },
                    {
                        label: "If no, please list the other countries:",
                        id: "requirements.other_countries",
                        type: "textarea",
                        optional: true
                    },

                    {
                        label: "Please describe the checks that you undertake on each person being provided a Cleva Card. " +
                            "At a minimum, the identity of the cardholder must be verified using photo ID and a DBS check " +
                            "undertaken: ",
                        warning: "Please note issuance outside of the UK is not permitted",
                        id: "requirements.kyc_process_details",
                        type: "textarea",
                        validators: [V.words(5, 1000)]
                    },
                    {
                        label: "Please outline the number of cards you intend to issue, the average load value, (including currency if more than one currency is required) and the load frequency, (for example, weekly/ monthly): ",
                        id: "requirements.cards_and_loads",
                        validators: [V.cardUsageMatrix()],
                        type: "matrix", options: {
                            rows: [
                                {label: "Year One", id: "first_year"},
                                {label: "Year Two", id: "second_year"},
                                {label: "Year Three", id: "third_year"}
                            ],
                            columns: [
                                {label: "Number of cards", id: "card_num"},
                                {label: "Average load", id: "avg_load"},
                                {label: "Load frequency", id: "load_freq", type: "select", options: [
                                        {key:"daily", value:"daily"},
                                        {key:"weekly", value:"weekly"},
                                        {key:"monthly", value:"monthly"},
                                        {key:"other", value:"other"}
                                ]},
                            ]
                        }
                    },

                ]
            },
            {
                id: "Directors",
                title: "Directors, Officers & Shareholders",
                subtitle: "Please identify below the details of all directors and ultimate beneficial owners, along with "+
                    "each individual shareholder that directly or indirectly owns 25% or more of the company.",
                info: "If you have any corporate shareholders directly or indirectly owning 25% or more of the company, "+
                    "please include their details in section 5 of this form. Note that where an individual holds multiple "+
                    "roles, their information need only be entered once and the relevant roles applicable selected in the "+
                    "tick boxes below.",
                fields: [
                    {
                        id: "directors", type: "list", fields: [
                            {label:"First name", id:"first_name", validators:[V.length(1,100)], className:"half-width"},
                            {label:"Middle name", id:"middle_name", validators:[V.length(0,100)], className:"half-width"},
                            {label:"Last name",
                                warning:"_if your last name has changed in the past 10 years, please include in brackets_",
                                id:"last_name", validators:[V.length(1,100)]},
                            {
                                label: "Date of birth",
                                id: `date_of_birth`,
                                type: "date-of-birth",
                                default: subYears(TODAY, 35).toISOString()
                            },
                            {
                                label: "nationality",
                                id: `nationality`,
                                type: "select",
                                options: countries,
                                default: 'GB',
                                optional:false
                            },
                            {label: "address line 1", id: `address_line_1`, warning: "Home address required ", validators: [V.length(1,100)]},
                            {label: "address line 2", id: `address_line_2`},
                            {label: "county", id: `address_line_3`},
                            {label: "town", id: `city`, validators: [V.length(1,100)]},
                            {label: "postcode", id: `postcode`, validators: [V.ukPostCode()]},
                            {label: "country", id: `country`, type: "select", options: countries, default: 'GB', optional:false},
                            {label: "Roles applicable:", id: "roles", type: "checklist", options: [
                                    {label: "Director/officer", id:"director"},
                                    {label: "Shareholder", id:"shareholder"},
                                    {label: "Ultimate beneficial owner", id:"ultimate_owner"},
                                    {label: "other", id:"other"}
                            ]},
                            {
                                label: "Percentage shareholding (leave empty if not applicable):",
                                id: "share_percent_persons",
                                type: "percentage",
                                className:"half-width",
                                validators: [V.num(0, 100, true)]
                            },
                            {label:"Any additional comments:", id:"shareholder_comment", type:"textarea", optional:true}
                            // {
                            //     label: "Identity verification status",
                            //     id: "kycStatus",
                            //     type: "kyc-status",
                            //     className:"half-width"
                            // },
                            // {label: "test status update", id: "kycStatus.status", optional:true},
                            // {label: "id reference", id: "idRef", type: "readonly", className:"half-width"},
                            // {label: "Identity verification status", id: "kyc_status", type: "readonly", className:"half-width"},

                        ]
                    },
                ]
            },
            // {
            //     id: "Shareholders",
            //     title: "Shareholder Details",
            //     subtitle: "Please include the details of each individual who owns more than 25% of the share capital. If you have any corporate shareholders, please use the corporate shareholders table below.",
            //     fields: [
            //         {
            //             id: "shareholders", type: "list", fields: [
            //                 {
            //                     label: "Percentage shareholding:",
            //                     id: "share_percent_persons",
            //                     type: "percentage",
            //                     validators: [V.num(25, 100)]
            //                 },
            //                 {label: "KYC status", id: "kyc_status", type: "kyc-form"},
            //             ]
            //         },
            //     ]
            // },
            {
                id: "ShareholdersCorporate",
                title: "Corporate Shareholder Details",
                subtitle: "Please identify below the details of all corporate shareholders directly or indirectly owning"+
                    " 25% or more of the company. If any corporate shareholders are incorporated outside of the UK, "+
                    "please send copies of the certificate of incorporation, register of directors, register of "+
                    "shareholders and a diagram explaining the structure to KYB@clevacard.com",
                fields: [
                    {
                        id: "shareholders-corpo", type: "list", fields: [
                            {
                                label: "Percentage shareholding:",
                                id: "share_percent_corpo",
                                type: "percentage",
                                validators: [V.num(0, 100)]
                            },
                            {label: "Company Name:", id: "company_name"},
                            {label: "Company Number:", id: "company_number"},
                            {label: "Country of Incorporation:", id: "country"},
                            {label: "Registered Office Address:", id: "office_address"},
                            {label: "Alternative Business Address (if applicable):", id: "business_address"},
                            {label: "Company Website:", id: "website"},
                            {label: "Industry/Primary Business Area:", id: "industry"},
                            {label: "Documents sent to KYB@clevacard.com:", id: "docs_sent", type: "checklist",
                                validators: [V.allChecked(3)], options: [
                                    {label: "cert of incorporation", id:"cert_of_incorporation"},
                                    {label: "register of directors", id:"register_of_directors"},
                                    {label: "register of shareholders", id:"register_of_shareholders"},
                            ]},
                        ]
                    },
                ]
            },
            {
                id: "SourceOfFunds",
                title: "Source of Funds",
                subtitle: "Please describe the source of the funds that will be loaded onto the Cleva cards",
                fields: [
                    {
                        label: "_For example, bank transfer received from your customer to your business account and then loaded onto Cleva card for spend._  Additional documents may be requested.",
                        id: "source_of_funds",
                        type: "textarea"
                    },
                ]
            },
            {
                id: "Declarations",
                title: "Declarations",
                subtitle: "Please complete the following table to confirm whether or not any of the company’s directors have ever been:",
                fields: [
                    {
                        label: "Disqualified from acting as a director within the United Kingdom or any other jurisdiction?",
                        id: "declarations.disqualified",
                        type: "yesno"
                    },
                    {
                        label: "The subject of, or party to, bankruptcy, insolvency or criminal legal proceedings within the United Kingdom or any other jurisdiction?",
                        id: "declarations.insolvency",
                        type: "yesno"
                    },
                    {
                        label: "The subject of, or party to, disciplinary proceedings of a regulatory or other professional body?",
                        id: "declarations.disciplinary",
                        type: "yesno"
                    },
                    {
                        label: "If the answer to any of the questions shown above is yes, please describe the circumstances in as much detail as possible:",
                        id: "declarations.explanation",
                        type: "textarea",
                        optional: true
                    },
                    {
                        label: "Do any of the Directors or Shareholders hold Directorships or Shareholding with any other Entity?",
                        id: "declarations.other_entities",
                        type: "yesno"
                    },
                    {
                        label: "If answered yes to the above, please provide details e.g. name of entity, registered address and position.",
                        id: "declarations.other_entities_details",
                        type: "textarea",
                        optional: true
                    },
                ]
            },
            {
                id: "comments",
                title: "Comments",
                subtitle: "Any further comments you would like to add",
                fields: [
                    {
                        label: "comments",
                        id: "final_comments",
                        optional: true
                    }
                ]
            },
            {
                id: "signature",
                title: "Signature",
                subtitle: "I confirm that all information given above is true and complete",
                fields: [
                    {
                        label: "Your full name",
                        id: "signature.fullname",
                        validators: [V.words(2)]
                    }
                ]
            }
        ]
    }
}

export default template
