import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// const { t } = this.props;
import './index.css'
import documentIcon from '../../assets/images/documents.png'
import { DefaultButton } from '../../components/button/index';
// import { postAPI } from "../../api/index";
import LinearProgress from '@material-ui/core/LinearProgress';
// import GetCleva from '../../components/getCleva';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
// import Loader from '../../components/loader/loader'

const useStyles = makeStyles(() => ({
    btnContainer: {
        width: '90%',
        maxWidth: '200px',
        margin: '10px auto'
    },
    colorPrimary: {
        backgroundColor: 'white',
    },
    barColorPrimary: {
        backgroundColor: '#7ed321',
    },
    barColorError: {
        backgroundColor: 'var(--brand-main-color)',
    }
}));


const DocumentUpload = (props) => {
    const [photos, setPhotos] = useState([])
    // const [uploader, setUploader] = useState(true)
    const [progress, setProgress] = useState(0);
    const [emailSend, setEmailSend] = useState(false)
    const [error, setError] = useState(false);
    let navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles()


    const getPhoto = (e) => {
        setProgress(0)
        setError(false)
        photos.push(e.target.files[0])
        setPhotos([...photos])


    }
    const upload = () => {
        setError(false)
        // let photo = photos[i]
        // let name = photo.name
        // let file = {}
        // for (let i = 0; i < photos.length; i++) {
        //     file[photos[i].name] = { size: photos[i].size }

        // }

        // let body = { "files": file }
        setProgress(10)

        // postAPI(`document_uploads/${uploader}`, body).then((response) => {
        //     let s3data = response.files ? response.files : []
        // console.log(s3data[name], "data")
        for (let i = 0; i < photos.length; i++) {
            // let name = photos[i].name
            photos[i].error = false

            // if (s3data[name])
            //     uploadFile(s3data[name], response, i)
            setProgress(100)
            let photo = photos[i]
            photo.success = true
            setPhotos([...photos])

        }

        // })
    }

    const sendEmail = () => {
        setEmailSend(true)
    }

    useEffect(() => {
        // let search = window.location.search;
        // let params = new URLSearchParams(search);
        // let uploader = params.get('uploader');
        // setUploader(uploader)
    }, [])




    // async function uploadFile(s3Data, response, i) {

    //     let postData = new FormData()
    //     for (const [key, value] of Object.entries(s3Data.fields)) {
    //         postData.append(key, value)
    //     }
    //     postData.append('file', photos[i])


    //     let res = await fetch(s3Data.url, {
    //         method: 'POST',
    //         body: postData
    //     });

    //     if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
    //         setProgress(0)
    //         let photo = photos[i]
    //         photo.error = true
    //         setError(true)
    //         setPhotos([...photos])
    //         return
    //     }

    //     setProgress(100)
    //     let photo = photos[i]
    //     photo.success = true
    //     setPhotos([...photos])

    //     let body = {
    //         action: 'website.document_upload.success',
    //         properties:
    //         {
    //             uploader_account_id: response.account_id,
    //             files: (response.files)
    //         }
    //     }
    //     postAPI('log', body).then(() => {

    //     })
    // }

    const deletePhoto = (i) => {
        photos.splice(i, 1);
        setPhotos([...photos])
    }

    const handleNavigate = () => {
        navigate('/checkout')
    }



    return (
        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                {/* <GetCleva /> */}
                <Grid container>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    <Grid item xs={12}
                        md={10}
                        lg={7}
                        xl={6}>

                        {<div className="doc-upload">

                            {!emailSend && <><div className="header">
                                <div className="heading">
                                    <h3>{t('upload-document.heading')}</h3>
                                </div>
                                <h4>{t('upload-document.description')}</h4>

                            </div>
                                {/* 
                            <div className="text">
                                <p>{t('upload-document.message')}
                                    <a href="https://help.osper.com/ordering-an-osper-card/your-first-osper-card/why-am-i-being-asked-for-proof-of-identity-or-address" target="_blank" rel='noopener noreferrer'>
                                        {t('upload-document.message1')}</a>. {t('upload-document.message2')}<b>{t('upload-document.passport')}</b> {t('upload-document.and')} <b>{t('upload-document.driving-liscence')}</b>. {t('upload-document.message4')}</p>
                            </div> */}

                                <form id="upload-form" name="upload-form" className="upload-form">

                                    <label htmlFor="upload-input" className="upload">
                                        <input id="upload-input"
                                            type="file" multiple accept="image/*,application/pdf,
                                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            // capture="camera"
                                            // required
                                            onChange={getPhoto} />

                                        <div className="upload-area">
                                            <img src={documentIcon} alt="Documents"
                                                className="icon icon--document" />
                                            <span className="icon icon--plus">
                                                <AddIcon />
                                            </span>
                                        </div>

                                        <p>Add a document...</p>
                                    </label>

                                    {photos.length > 0 && <div id="preview-area" className="preview-area" style={{ display: 'block' }}>
                                        <ul id="file-list" className="file-list">
                                            {photos && photos.map((photo, i) => {
                                                return (<li className={`file-list__item ${error && photo.error ? 'fail' : ''}`}>
                                                    <span className="file-list__item__section">
                                                        {((progress !== 100 && !photo.success) || photo.error) &&
                                                            <span className="delete-file icon-delete" onClick={() => deletePhoto(i)}>
                                                            </span>
                                                        }
                                                        {photo.success && <span className="file-uploaded icon-tick-open">
                                                        </span>}
                                                    </span>

                                                    <span className="file-list__item__section file-name">{photo.name}
                                                    </span>

                                                    <span className="file-list__item__section">
                                                        <span className="upload-progress">
                                                            {/* <span className="upload-progress__bar bar"> */}
                                                            {(progress === 100 || progress === 0) &&
                                                                < LinearProgress variant="determinate" value={error || photo.success ? 100 : progress}
                                                                    classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: photo.error ? classes.barColorError : classes.barColorPrimary }} />
                                                            }
                                                            {progress === 10 && < LinearProgress classes={{ colorPrimary: classes.colorPrimary }} />
                                                            }                                            {/* </span> */}
                                                        </span>
                                                    </span>
                                                </li>)
                                            })}
                                        </ul>
                                        {error && <div className="upload-error" role="alert">{t('upload-document.error')}</div>
                                        }
                                        <div className={classes.btnContainer}>
                                            {(progress !== 100 || error) && < DefaultButton onClick={upload} btnType="button" name={error ? 'Retry' : 'Upload'}></DefaultButton>}
                                            {/* {progress === 100 && !error && <button className='btn-lightgreen'
                                            onClick={() => props.history.push('/v2/kyc-confirm')}>
                                            {t('upload-document.success')}</button>} */}
                                        </div>
                                    </div>}



                                </form>
                                {progress !== 100 && < DefaultButton onClick={sendEmail} btnType="button" name={t('upload-document.not-now')}></DefaultButton>}
                                {progress === 100 && <DefaultButton btnType="button" name={t('next')} onClick={handleNavigate} />}
                                {/* <div className="text">
                                <p>{t('upload-document.text-title')}</p>
                                <ul>
                                    <li>{t('upload-document.doc1')}</li>
                                    <li>{t('upload-document.doc2')}</li>
                                    <li>{t('upload-document.doc3')}</li>
                                </ul>
                                <p>{t('upload-document.note')}</p>
                            </div> */}
                            </>}
                            {emailSend && <>
                                <div className="header">

                                    <h4 className='text-center'>{t('upload-document.email-send')}</h4>

                                </div>
                                <DefaultButton btnType="button" name={t('upload-document.ok')} onClick={handleNavigate} />
                            </>
                            }
                        </div>
                        }
                        {/* {!uploader && <h2>{t('upload-document.not-found')}</h2>} */}
                        {/* <Loader /> */}
                    </Grid></Grid></Grid></div >

    )
}



export default DocumentUpload;




