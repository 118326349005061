import React from 'react';
import { DefaultButton } from '../../components/button/index';
import { FormRowWithLabel, FormLabel } from '../../components/formRows/formRows';
import { useTranslation } from "react-i18next";
import { Form } from 'formik';
import { CustomTextField } from '../../components/textField/textField';
import TestHelper from "../../utils/testUtils";



const BasicForm = (props) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        error
    } = props;

    return (
        <Form>
            <TestHelper setFieldValue={props.setFieldValue} />
            <div className='padDiv'>
                <CustomTextField {...props} fieldName="code" autoComplete="code" />
                {error && <FormRowWithLabel>
                    <FormLabel></FormLabel>
                    <p className='errorMessage'>{error}</p>
                </FormRowWithLabel>}

                <FormRowWithLabel className='next-button-container'>
                    <FormLabel />
                    <DefaultButton onClick={handleSubmit} name={t('submit')} id='next-btn'></DefaultButton>
                </FormRowWithLabel>
            </div>
        </Form>

    );
}

export default BasicForm;
