import React from 'react'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// import Loader from '../../ui-components/loader/loader'
import './index.css'
import { Formik, Form } from 'formik';
import { FormRowWithLabel, FormLabel, FormSpacer } from '../../components/formRows/formRows';
import { CustomTextField } from '../../components/textField/textField';
import { DefaultButton } from '../../components/button/index';
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";





const CheckOut = () => {


    const { t } = useTranslation();
    const navigate = useNavigate()
    const { state } = useLocation();
    const { total, monthly_fee, signup_fee } = state;

    const validationSchema = Yup.object({
        number_of_cards: Yup.number()
            .required(`${t('field-rqd')} ${t('number_of_cards_placeholder')}`)
    });

    const handleSubmit = () => {
        navigate('/success')
    }



    return (
        <div className='parent'>

            <Grid item xs={12}
                sm={9}
                md={8}
                lg={6}
                className='wrapper' >
                {/* <GetCleva /> */}
                <Grid container>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    <Grid item xs={12}
                        md={10}
                        lg={7}
                        xl={6}>
                        <div className="form-group confirm-kyc-group text-left">


                            <div className='amount-container'>
                                <div className="checkout-detail">
                                    <span className="confirm-value">{t('monthly-fee')}:</span>
                                    <span className="confirm-value">{monthly_fee}</span>
                                </div>
                                <div className="checkout-detail">
                                    <span className="confirm-value">{t('signup-fee')}:</span>
                                    <span className="confirm-value">{signup_fee}</span>
                                </div>
                                <div className="checkout-detail">
                                    <span className="confirm-value">{t('total')}</span>
                                    <span className="confirm-value">{total}</span>
                                </div>
                            </div>

                        </div>
                        <Formik
                            initialValues={{ number_of_cards: "" }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(props) => (<><Form>
                                <div className='padDiv amount-container'>
                                    <CustomTextField {...props}
                                        fieldName="card_number" autoComplete="code" type="number" />
                                    <CustomTextField {...props}
                                        fieldName="exp_date" autoComplete="code" type="number" />
                                    <CustomTextField {...props}
                                        fieldName="cvv" autoComplete="code" type="number" />
                                    <FormSpacer />
                                </div>
                            </Form>
                                <FormRowWithLabel className='next-button-container'>
                                    <FormLabel />
                                    <DefaultButton onClick={handleSubmit} name={t('next')} id='next-btn'></DefaultButton>
                                </FormRowWithLabel>
                            </>)}

                        </Formik>


                    </Grid>
                    <Grid item
                        md={1}
                        lg={2}
                        xl={3}
                    />
                    {/* <Loader /> */}
                </Grid></Grid></div>
    )

}






export default CheckOut
