import React, {useEffect, useState} from "react";
import {atom, useRecoilState} from "recoil";
import {useSearchParams} from "react-router-dom";
import ClevaAPI from "../../api/ClevaAPI";
import {FormField, ObjectToList} from "../B2BOnboarding/kybFormComponents";
import {B2BHeader} from "../B2BOnboarding";
import DocumentSlot from "./documentSlot";
import Cache, {restoreCachedData, setupPollingOnUserActivity, STATES} from "../../utils/cache";
import StatusIndicator from "../../components/statusIndicator";
import {subYears} from "date-fns";
import {Pointer, ValidationRunner, ValidationUI, Validators} from "../B2BOnboarding/validation";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee, faUser} from "@fortawesome/free-solid-svg-icons";
import Countries from "../../utils/countries";
import KYC_STATUS, {ACTIONS} from "./constants";
import EXAMPLE_ID from '../../assets/images/kyc-ID-example-001.jpg'
import EXAMPLE_POA from '../../assets/images/kyc-POA-example-001.jpg'
import './b2bkyc.css'


const cache = Cache()
const V = Validators
const validator = ValidationRunner()


const B2BKYC = () => {
    const api = ClevaAPI()
    const session = atom({
        key: 'User',
        default: {},
    });

    const countries = Countries.asList()
    const [searchParams] = useSearchParams();
    const formRef = searchParams.get('ref')
    const prefix = searchParams.get('key')
    const [user, setUser] = useRecoilState(session)
    const [kycResult, setKycResult] = useState({})
    const [kycStatus, setKycStatus] = useState(KYC_STATUS.EKYC)
    const [uiStatus, setUiModalMsg] = useState('')
    const [cardholderRef, setCardholderRef] = useState('')
    const [uploads, setUploads] = useState({ID:null, POA:null})
    const [validationMsgs, setValidationMsgs] = useState([])

    cache.addItemChangeCallback((key, info) => {
        if(key === `${prefix}.cardholder_ref`){
            console.log("cardholderRef CHANGE:", info.val)
            setCardholderRef(info.val)
        }
    })

    let validationTimer
    const scheduleValidation = () => {
        if(validationTimer) clearTimeout(validationTimer)
        validationTimer = setTimeout(validateNow, 1000)
    }

    const validateNow = () => {
        if(validationTimer) clearTimeout(validationTimer)
        validationTimer = false
        const msgs = validator.run()
        setValidationMsgs(msgs)
        return msgs
    }

    const personFields = [
            {label: "First name", id: `${prefix}.first_name`, validators: [V.length(1, 100)]},
            {label: "Last name", id: `${prefix}.last_name`, validators: [V.length(1, 100)]},
            {
                label: "Date of birth",
                id: `${prefix}.date_of_birth`,
                type: "date-of-birth",
                default: subYears(new Date(), 35).toISOString()
            },
            {
                label: "nationality",
                id: `${prefix}.nationality`,
                type: "select",
                options: countries,
                default: 'GB'
            },
            {label: "address line 1", id: `${prefix}.address_line_1`, className: "space-before"},
            {label: "address line 2", id: `${prefix}.address_line_2`},
            {label: "county", id: `${prefix}.address_line_3`},
            {label: "town", id: `${prefix}.city`},
            {label: "postcode", id: `${prefix}.postcode`, validators: [V.ukPostCode()]},
            {label: "country", id: `${prefix}.country`, type: "select", options: countries, default: 'GB'},
            {label: "person ref", id: `${prefix}.cardholder_ref`, type: "readonly"},
            // {label:"email", id:`${prefix}.email`, type:"email", optional:true},
            // {label:"mobile", id:`${prefix}.mobile`, type:"telephone", optional:true}
        ]

    const getUser = async () => {
        const u = await api.getUser()
        console.log("USER:", u)
        setUser(u)
    }

    const loadLastResult = async (formRef, cardholderRef) => {
        if(!formRef || !cardholderRef) return
        try {
            const lastResult = await api.ekycGetLast(formRef, cardholderRef)
            setKycResult(lastResult)
            if(!lastResult || lastResult.error || lastResult.detail)
                throw new Error("Error response from server")
            setKycStatus(lastResult.msg)
            cache.setItem(`${prefix}.kycStatus`, lastResult.msg, true)
            console.log(lastResult)
        } catch (e) {
            setKycResult({})
            console.error(e)
        }
    }

    const handleChange = (e) => {
        //console.log("CHANGE event:",  e.target.dataset.id, e.target.value)
        cache.handleChangeEvent(e)
        //scheduleValidation()
    }

    const queryForUpdates = async () => {
        const updates = await cache.loadRemote()
        if (updates && updates.length) {
            restoreCachedData(updates)
        }
    }

    const changeStatus = (status) => {
        if(!status) debugger
        cache.setItem(`${prefix}.kycStatus`, status)
        setKycStatus(status)
        loadLastResult(formRef, cardholderRef)
    }
    
    const onSubmit = async () => {
        const errors = validateNow()
        if (errors && errors.length) {
            return
        }
        //setKycStatus('')
        setUiModalMsg('processing')
        const inputElements = document.querySelectorAll("[data-id]")
        const siblings = Array.from( inputElements ).filter( el => el.dataset.id.startsWith(prefix) )
        const prefixDot = `${prefix}.`
        const person = siblings.reduce( (p, el) => {
            const key = el.dataset.id.replace(prefixDot, '')
            p[key] = el.value
            return p
        }, {})
        //console.log(person)
        const res = await api.ekyc(formRef, person)
        setUiModalMsg('')
        console.log("result:", res)
        if(res && res.msg) {
            changeStatus(res.msg)
            if(res.cardholder_ref){
                cache.setItem(`${prefix}.cardholder_ref`, res.cardholder_ref)
            }
            setKycResult(res)
        } else {
            alert("Server error")
            //setKycStatus('EKYC')
            setUiModalMsg('')
        }

    }

    const onFirstLoad = () => {
        setUiModalMsg('')
        cache.removeStatusCallback(onFirstLoad)
    }

    const checkIfUploadsComplete = (uploads) => {
        loadLastResult()
    }

    const registerUploadedFile = (docType, meta) => {
        console.log("Adding file:", meta, "to:", uploads)
        const update = Object.assign({}, uploads)
        update[docType] = meta
        setUploads(update)
        checkIfUploadsComplete(update)
    }

    const getLastUploads = async (formRef, cardholderRef) => {
        await loadLastResult(formRef, cardholderRef)
        const uploadsCopy = Object.assign({}, uploads)
        for(let docType in uploadsCopy){
            if(!uploadsCopy[docType]){
                const meta = await api.checkScanResult(formRef, cardholderRef, '', docType)
                if(meta.url) {
                    meta.onProgress = true
                    meta.src = meta.url
                    meta.percent = 100
                    meta.file = {
                        type: meta.type
                    }
                    uploadsCopy[docType] = meta
                }
            }
        }
        setUploads(uploadsCopy)
        checkIfUploadsComplete(uploadsCopy)
    }

    useEffect(() => {
        cache.addStatusCallback(onFirstLoad)
        cache.setRef(formRef)
        getUser()
        setUiModalMsg('loading')
        restoreCachedData(cache.entries())
        setUiModalMsg('')
        const storedCardholderRef = cache.getItem(`${prefix}.cardholder_ref`).val
        if(storedCardholderRef) setCardholderRef(storedCardholderRef)
        getLastUploads(formRef, storedCardholderRef || cardholderRef)
        return setupPollingOnUserActivity(queryForUpdates)
    }, [])

    const mapStatusMsg = (kycResult) => {
        if(!kycResult || !kycResult.msg) return ""
        switch(kycResult.msg){
            case "PASS": return ACTIONS.PASS
            case "FAIL": return "Information submitted successfully, pending review."
            case "EKYC":
                if(kycResult.attempts_left == kycResult.max_attempts) return "Please provide the personal details"
                if(kycResult.attempts_left < kycResult.max_attempts) return "Please make sure the details are accurate"
            case "SCAN":
                if(kycResult.attempts_left == kycResult.max_attempts) return ACTIONS.SCAN
                if(kycResult.attempts_left < kycResult.max_attempts) return "Please upload another"
            default: return "Please provide the personal details"
        }
    }

    const KYCHeader = ({kycResult}) => {
        const kycStatusFriendly = mapStatusMsg(kycResult)
        let msgTimer
        const [msgState, setMsgState] = useState('show')
        const minimizeMsg = () => {
            setMsgState('minimized')
        }
        useEffect(() => {
            setMsgState('maximized')
            msgTimer = setTimeout(minimizeMsg, 2000)
            return () => {clearTimeout(msgTimer)}
        }, [])

        return <header className="form-section">
            {kycStatusFriendly ? <h2 className={`kycMessage ${msgState}`}>{kycStatusFriendly}</h2> : ""}
        </header>
    }

    const PersonForm = ({kycStatus, fields, onSubmit}) => {
        validator.clear()
        const propagateChange = (e) => {
            changeStatus(e.target.value)
        }
        const cssStatus = (kycStatus && ['REFER','PASS'].includes(kycStatus)) ? 'disabled' : ''
        return <div className={`personForm ${cssStatus}`}>
            {
                fields.map(f => <FormField id={f.id}
                                           key={f.id}
                                           defaultValue={cache.getItem(f.id).val || f.default}
                                           options={f.options}
                                           label={f.label}
                                           type={f.type}
                                           className={f.className}
                                           validationId={validator.addRule(f)}
                />)
            }
            <FormField type="readonly"  label="" >
                <input type="button" className="autoclick" data-id={`${prefix}.kycStatus`} onClick={propagateChange}/>
            </FormField>

            <div className="form-field">
                <button onClick={onSubmit} >submit</button>
            </div>

        </div>
    }

    const Uploads = ({cardholderRef}) => {
        return <div className="documentSlots">

            <DocumentSlot label="Proof of Indentity" cacheRef={formRef} personRef={cardholderRef} docType="ID"
                          existingFileMeta={uploads.ID}
                          placeholderImg={EXAMPLE_ID}
                          onUploadComplete={registerUploadedFile}>
                <ul>
                    <li>passport</li>
                    <li>driving licence</li>
                    <li>national ID card</li>
                    <li>It has to be still valid and IN COLOR (not black and white)</li>
                </ul>
            </DocumentSlot>

            <DocumentSlot label="Proof of Address" cacheRef={formRef} personRef={cardholderRef} docType="POA"
                          existingFileMeta={uploads.POA}
                          placeholderImg={EXAMPLE_POA}
                          onUploadComplete={registerUploadedFile}>
                <ul>
                    <li>utility bill - dated in the last 3 months</li>
                    <li>bank statement - dated in the last 3 months</li>
                    <li>NO OTHER DOCUMENT will be accepted as POA (no driving licence)</li>
                </ul>
            </DocumentSlot>
        </div>
    }

    const StatusBar = ({cache}) => {
        const [savingStatus, setSavingStatus] = useState(STATES.LOADING)
        const callStatusCallback = (s) => {
            setSavingStatus(s)
        }
        cache.addStatusCallback(callStatusCallback)
        return <StatusIndicator savingStatus={savingStatus}/>
    }

    const ValidationBar = ({validationMsgs}) => {
        const [pointerTarget, setPointerTarget] = useState(false)

        const handleProblemClick = (e) => {
            console.log("clicked problem ", e.currentTarget)
            const id = e.currentTarget.dataset.fieldid
            const inputs = Array.from(document.querySelectorAll(`[data-id='${id}']`))
            let input = inputs[0]
            console.log("pointing to input:", input)
            setPointerTarget(input)
            input.focus()
            input.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        }

        console.log("ERRORS", validationMsgs)
        return <>
            <ValidationUI problems={validationMsgs} onProblemClick={handleProblemClick} />
            <Pointer target={pointerTarget}/>
        </>
    }

    const AdminControls = () => {

        const runCheck = async (stage) => {
            const res = await api.reset_ekyc(formRef, cardholderRef, stage)
            setKycResult(res)
            window.location.reload(false);
        }
        const setKycStage = (e) => {
            const stage = e.target.dataset.stage
            runCheck(stage)
        }

        return <div>
            { kycResult ? <ObjectToList obj={kycResult} title="eKYC results"/> : "" }
            { uploads ? <ObjectToList obj={uploads} title="ID Scan results"/> : "" }
            <div>
                <button onClick={setKycStage} data-stage={KYC_STATUS.EKYC}>Reset to eKYC</button>
                <button onClick={setKycStage} data-stage={KYC_STATUS.SCAN}>Reset to Upload Docs</button>
                <button onClick={setKycStage} data-stage={KYC_STATUS.PASS}>Approve KYC</button>
                <button onClick={setKycStage} data-stage={KYC_STATUS.FAIL}>Force fail KYC</button>
            </div>
        </div>
    }

    console.log("kycStatus:",kycStatus)
    const isAdmin = user && user.is_authenticated

    return <div className="wrapper" onChange={handleChange}>
        <StatusBar cache={cache}/>

        <ValidationBar validationMsgs={validationMsgs}/>
        <B2BHeader title="Identity and Address Verification"/>
        <KYCHeader kycResult={kycResult}/>
        <div className="form-section kyc">
            { kycStatus == KYC_STATUS.SCAN ? <Uploads cardholderRef={cardholderRef}/> : '' }
            { kycStatus == KYC_STATUS.EKYC ? <PersonForm fields={personFields} onSubmit={onSubmit} kycStatus={kycStatus}/> : '' }
            { kycStatus == 'REFER' ? <PersonForm fields={personFields} onSubmit={onSubmit} kycStatus='KYC_STATUS.EKYC'/> : '' }
            { isAdmin ? <AdminControls/> : ''}
        </div>

        <div className={`modal ${uiStatus}`}>{uiStatus}</div>
    </div>
}


export default B2BKYC