import React, { useEffect } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';




const KycProgress = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/document-upload')
        }, 2000)
    }, [])

    return (
        <div className='parent center'>

            <CircularProgress color="blue" size={100} />

        </div>
    )

}






export default KycProgress
