import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormRowWithLabel, FormLabel } from '../formRows/formRows';
import { useTranslation } from 'react-i18next';
import FocusUtils from "../../utils/focusUtils";
import { keyPressCommon } from '../../utils/commonUtils'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  labelDiv: {
    color: '#454d5c',
  },
  inputTextField: {
    width: '100%',
    border: 'none',
    margin: '4px 0',
    color: '#000',
    '& input': {
      fontSize: '1.6rem',
      padding: '1rem 1.6rem'
    }
  },
}));

const StyledInput = withStyles({
  root: {
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "red"
    }
  }
})(TextField);

export default function TextFields(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(props.visibleByDefault ? true : false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    props.type === 'password' ?
      <StyledInput {...props}
        variant="filled"
        className={`${classes.inputTextField} ${props.className}`}
        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
        InputProps={{ // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }} />
      :
      <StyledInput {...props}
        variant="filled"
        className={`${classes.inputTextField} ${props.className}`}
        inputProps={{ maxLength: props.maxLength, min: 0 }} />
  );
}


export const CustomTextField = (props) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    fieldName,
    autoComplete,
    mobile,
    disabled,
    type
    // ...rest
  } = props;


  const keyPress = (e) => {
    keyPressCommon(e, focus)
  }



  let focus
  useEffect(
    () => {
      focus = FocusUtils()
    }
  )
  return <FormRowWithLabel>
    <FormLabel>{t(fieldName
    )}</FormLabel>
    <TextFields id={fieldName}
      name={fieldName}
      placeholder={t(`${fieldName}`)}
      helperText={touched[fieldName] ? errors[fieldName] : ""}
      error={(touched[fieldName] && Boolean(errors[fieldName])) || undefined}
      value={mobile ? values[fieldName] || "07" : values[fieldName]}
      onKeyDown={keyPress}
      onChange={handleChange}
      variant="filled"
      onBlur={handleBlur}
      autoComplete={autoComplete}
      disabled={disabled}
      type={type}
    // {...rest}
    />
  </FormRowWithLabel>
}
