import "./testHelper.css"


const TestHelper = ({setFieldValue}) => {
    const input = {
        personKycOk : {
            first_name: "Alfred",
            last_name: "Pennyworth",
            email: "alfred@osper.com",
            gdpr: true,
            postcode: "E1 7AY",
            street_address_1: "St James's Passage",
            street_address_2: "",
            city: "London",
            mobile_number: "07123456789",
            day: "01",
            month: "01",
            year: "2000",
            gender: "MALE"
        },
        personKycSoftFail : {
            first_name: "James",
            last_name: "Letdie",
            email: "jamessoft@osper.com",
            gdpr: true,
            postcode: "E1 7AY",
            street_address_1: "St James's Passage",
            street_address_2: "",
            city: "London",
            mobile_number: "07123456788",
            day: "01",
            month: "01",
            year: "2000",
            gender: "MALE"
        },
        personKycHardFail : {
            first_name: "Jim",
            last_name: "Skyfall",
            email: "jimhard@osper.com",
            gdpr: true,
            postcode: "E1 7AY",
            street_address_1: "St James's Passage",
            street_address_2: "",
            city: "London",
            mobile_number: "07123456787",
            day: "01",
            month: "01",
            year: "2000",
            gender: "MALE"
        },
        personValidationFail : {
            first_name: "Jim",
            last_name: "Wrongfone",
            email: "wrong email",
            gdpr: true,
            postcode: "E1 7AY",
            street_address_1: "St James's Passage",
            street_address_2: "behind the bar",
            city: "London",
            mobile_number: "123",
            day: "01",
            month: "01",
            year: "2000",
            gender: "MALE"
        },
    }
    const fillIn = (e) => {
        let personType = e.currentTarget.name
        let data = input[personType]
        if(setFieldValue) {
            for(var k in data){
               setFieldValue(k, data[k])
            }

        }
    }

    return <div className={"testHelper"}>
        <small>test helper</small>
        <button onClick={fillIn} name="personKycOk">person KYC OK</button>
        <button onClick={fillIn} name="personKycSoftFail">person KYC Soft Fail</button>
        <button onClick={fillIn} name="personKycHardFail">person KYC Hard Fail</button>
        <button onClick={fillIn} name="personValidationFail">person With Wrong Email</button>
    </div>;
}

export default TestHelper