import {useEffect, useState} from "react";
import './kybForm.css'
import template from "./kybFormModel";
import Cache, {restoreCachedData, setupPollingOnUserActivity, STATES} from "../../utils/cache"
import StatusIndicator from "../../components/statusIndicator";
import {FormField, FormSection, ObjectToList} from "./kybFormComponents";
import {Pointer, ValidationRunner, ValidationUI} from "./validation";
import KycStatus from "./kycStatus";
import ClevaAPI, {FormStatus} from "../../api/ClevaAPI";
import Countries from "../../utils/countries";

const cache = Cache()
const api = ClevaAPI()
let validator = ValidationRunner()


const renderFieldSetFn = (fields, idPrefix) => {
    const renderFieldSet = (model, i) => {
        return <div className="fieldset" data-index={i+1}>
            <header>
                <span className="icon index">{i+1}</span>
                <span className="icon button removeItem" data-id={i}>x</span>
            </header>
            {fields.map( template_field => {
                const field = Object.assign({}, template_field);
                field.id = `${idPrefix}.${i}.${template_field.id}`
                field.default = model ? model[template_field.id] : cache.getItem(field.i)
                return renderFormField(field)
            } )}
        </div>
    }
    return renderFieldSet
}


const List = ({defaultItems, renderItemFn, id}) => {
    const [items, setItems] = useState(defaultItems)
    //console.log("List " + id + " rendering")

    const addListItem = (e) => {
        const newItems = items.concat({})
        setItems(newItems)
        cache.setItem(id, newItems.length)
    }
    const handleOnClick = (e) => {
        if(e.target.classList.contains("removeItem")){
            removeListItem(e)
        }
    }
    const removeListItem = (e) => {
        const index = parseInt(e.target.dataset.id)
        if(isNaN(index)) return;
        cache.removeListItem(id, index)
        const newItems = [...items]
        newItems.splice(index, 1)
        setItems(newItems)
        cache.setItem(id, {val:newItems.length})
    }

    useEffect( () => {
        restoreCachedData(cache.entries())
    }, [items])

    return <div className="listOfFieldsets" onClick={handleOnClick} data-id={id}>
                {items.map( (_, i) => {
                    return renderItemFn(_, i)
                } )}
                <header onClick={addListItem}>
                    <span className="list button">add another</span>
                    <span className="icon button" >+</span>
                </header>
            </div>
}

const updateCallback = (fieldId, result) => {
    cache.setItem(fieldId, result)
}

const renderFormField = f => {
    switch(f.type) {
        case "list":
            const renderFn = renderFieldSetFn(f.fields, f.id)
            const cachedSize = parseInt(cache.getItem(f.id).val) || 0
            const items = new Array(cachedSize).fill({})
            return <List
                defaultItems={items}
                renderItemFn={renderFn}
                key={f.id}
                id={f.id}
            />
        case "kyc-status":
            return <KycStatus form_key={f.id}
                              key={f.id}
                              label={f.label}
                              warning={f.warning}
                              className={f.className}
                              cache_id={cache.getRef()}
                              previousResultId={f.default}
                              updateCallback={updateCallback}
                              validator={validator.addRule(f)}/>

        default:
            return <FormField
                label={f.label}
                defaultValue={f.default}
                warning={f.warning}
                type={f.type}
                id={f.id}
                key={f.id}
                options={f.options}
                className={f.className}
                validationId={validator.addRule(f)}
            />
    }
}

const KybForm = ({applicationId, user}) => {

    const [pointerTarget, setPointerTarget] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [formStatus, setFormStatus] = useState(FormStatus.OPEN)
    const [validationMsgs, setValidationMsgs] = useState([])
    const [model, setModel] = useState({})
    const [savingStatus, setSavingStatus] = useState(STATES.LOADING)
    const callStatusCallback = (s) => {
        setSavingStatus(s)
    }
    cache.setStatusCallback(callStatusCallback)

    const handleChange = (e) => {
        //console.log("CHANGE event:",  e.target.dataset.id, e.target.value)
        if(formStatus === FormStatus.OPEN) {
            cache.handleChangeEvent(e)
            setPointerTarget(false)
        } else {
            alert("The form is already " + formStatus + ", no further changes allowed. Please contact the Support.")
        }
    }

    const handleProblemClick = (e) => {
        console.log("clicked problem ", e.currentTarget)
        const id = e.currentTarget.dataset.fieldid
        const inputs = Array.from(document.querySelectorAll(`[data-id='${id}']`))
        let input = inputs[0]
        if(!input) debugger
        console.log("pointing to input:", input)
        setPointerTarget(input)
        input.focus()
        input.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }

    let validationTimer
    const scheduleValidation = () => {
        if(validationTimer) clearTimeout(validationTimer)
        validationTimer = setTimeout(validateNow, 1000)
    }
    const validateNow = () => {
        if(validationTimer) clearTimeout(validationTimer)
        validationTimer = false
        const msgs = validator.run()
        setValidationMsgs(msgs)
        if(formStatus === FormStatus.OPEN) {
            cache.setItem("validation", msgs)
        }
        return msgs
    }

    const init = async () => {
        await cache.setRef(applicationId)
        let countries = await Countries.asListAsync()
        setModel(template(countries))
        setHasError(!cache.exists())
        const status = await cache.checkStatus()
        setFormStatus(status)
        restoreCachedData(cache.entries())
        validateNow()
    }

    const submit = async () => {
        const msgs = validateNow()
        await cache.sendNow() // to save the latest validation result
        if(msgs.length > 0){
            alert("Please review the list in red to fix the issues.")
        } else {
            if(window.confirm("\n\nOnce submitted, the form becomes uneditable until re-opened by the Support Team. \n\nOK to submit?")) {
                debugger
                const submitResult = await api.submitForm(applicationId, JSON.stringify(cache.entries()))
                setFormStatus(FormStatus.SUBMITTED)
                alert("Thank you, our team will review the data and follow up soon.")
            }
        }
    }

    const AllStoredData = ({refreshObj}) => {
        const data = cache.entries().reduce( (acc, [key, info]) => {
            acc[key] = info.val
            return acc
        }, {} )
        return <ObjectToList obj={data} title={refreshObj}/>
    }

    const queryForUpdates = async () => {
        const updates = await cache.loadRemote()
        //console.log("UPDATES: ", updates)
        if(updates && updates.length) {
            restoreCachedData(updates)
        }
    }    
    
    useEffect( () => {
        init()
        setupPollingOnUserActivity(queryForUpdates)
    }, [])

    if(!model || !model.sections) return <div>No form structure found</div>
    validator.clear()

    return <div className={`kyb-form ${formStatus}`} onChange={handleChange} onBlur={scheduleValidation}>
        {hasError ? <h1 className="errorMessage">The reference {applicationId} not found, please contact Cleva.</h1> : ""}
        <StatusIndicator savingStatus={savingStatus}/>
        { (user && user.is_authenticated) ?
            <FormSection title="Cleva Admin">{ model.meta.fields.map( renderFormField ) } </FormSection>: null }
        {
            model.sections.map( (section, index) => {
                const fields = section.fields.map( renderFormField )
                return <FormSection title={section.title} index={index+1} key={index} subtitle={section.subtitle} info={section.info}>
                    {fields}
                </FormSection>
            })
        }
        <footer className="form-section">
            <span>Further enquiries may be necessary depending upon the nature of the responses and documents which have been provided.</span>
            <button onClick={submit}>submit</button>
        </footer>
        <ValidationUI problems={validationMsgs} onProblemClick={handleProblemClick}/>
        <Pointer target={pointerTarget}/>

        { (user && user.is_authenticated) ? <AllStoredData refreshObj={savingStatus}/> : ""}
    </div>
}

export default KybForm